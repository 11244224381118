import React from "react";
import { Controller } from "react-hook-form";
import { View, Switch } from "react-native";
import {
  Box,
  Container,
  ErrorText,
  FormContainer,
  InputWrapper,
} from "./style";
import Header from "../../components/Header";
import Button from "../../components/Button";
import TextInput from "../../components/TextInput";
import ViewportProvider from "../../hooks/ViewPortProvider";
import { useMyAccount } from "./useMyAccount";
import { ActivityIndicator } from "react-native-paper";
import THEME from "../../config/theme";
import Toast from "react-native-toast-message";
import toastConfig from "../../components/Toast/toast.config";
import { useCustomTheme } from "../../contexts/useCustomTheme";
import { StandardText, SmallText } from "../../config/theme/globalStyles";
import { useRemoteConfigContext } from '../../contexts/useRemoteConfigContext';

export function MyAccount({ navigation }) {
  const {
    MobileOrDesktopForm,
    onSubmit,
    control,
    errors,
    handleSubmit,
    isLoadingUserInfo,
    isPendingUpdatedUserInfo,
    setValue,
    MobileOrDesktopContainer,
    isUrlValid,
    auth,
    termsOfUseUrl,
    setTermsOfUseUrl,
    webUrl,
    setWebUrl,
    salesPageUrl,
    setSalesPageUrl,
    formEmbed,
    setFormEmbed,
    androidUrl,
    setAndroidUrl,
    iosUrl,
    setIosUrl,
    myStoreUrl,
    setMyStoreUrl,
    hasMyStore,
    setHasMyStore
  } = useMyAccount();

  const user = auth.currentUser;

  const { customColor } = useCustomTheme();
  const config = useRemoteConfigContext();
  const hasForm = config.hasForm;
  const hasIAP = config.hasIAP;

  return (
    <ViewportProvider>
      <Container>
        <Header navigation={navigation} isAdminPage />
        <MobileOrDesktopContainer>
          <Box>
            {isLoadingUserInfo ? (
              <View style={{ height: "30rem" }}>
                <ActivityIndicator
                  style={{
                    flex: 1,
                    backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
                    justifyContent: "center",
                  }}
                  color={
                    customColor && customColor.primary
                      ? customColor.primary
                      : THEME.COLORS.PRIMARY_800
                  }
                />
              </View>
            ) : (
              <>
                <MobileOrDesktopForm>
                  <FormContainer>
                    <StandardText color='black' textAlign='left' margin='0.5rem 0rem'>
                      Configurações da minha conta:
                    </StandardText>
                    <InputWrapper>
                      <Controller
                        control={control}
                        name="Nome_Completo"
                        rules={{ required: "Nome completo é obrigatório" }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <TextInput
                              color="black"
                              backgroundColor="white"
                              label="Nome completo"
                              placeholder="Digite seu nome completo"
                              onBlur={onBlur}
                              onChangeText={onChange}
                              value={value}
                              error={errors.name}
                            />
                            {errors.name && (
                              <ErrorText>{errors.name.message}</ErrorText>
                            )}
                          </>
                        )}
                      />
                    </InputWrapper>

                    <InputWrapper>
                      <TextInput
                        color="black"
                        backgroundColor="white"
                        label="E-mail"
                        value={user.email}
                        editable={false}
                      />
                    </InputWrapper>

                    <InputWrapper>
                      <Controller
                        control={control}
                        name="appInformation.appName"
                        rules={{ required: "Nome do aplicativo é obrigatório" }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <TextInput
                              color="black"
                              backgroundColor="white"
                              label="Nome do aplicativo"
                              placeholder="Digite o nome do app"
                              onBlur={onBlur}
                              error={errors.appInformation?.appName}
                              onChangeText={onChange}
                              value={value}
                            />
                            {errors.appInformation?.appName && (
                              <ErrorText>
                                {errors.appInformation?.appName.message}
                              </ErrorText>
                            )}
                          </>
                        )}
                      />
                    </InputWrapper>

                    <InputWrapper>
                      <Controller
                        control={control}
                        name="appInformation.supportContact"
                        rules={{ required: "Contato para suporte é obrigatório" }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <TextInput
                              color="black"
                              backgroundColor="white"
                              label="Contato de suporte para os alunos"
                              placeholder="Digite o contato de suporte (email ou telefone)"
                              onBlur={onBlur}
                              error={errors.appInformation?.supportContact}
                              onChangeText={onChange}
                              value={value}
                            />
                            {errors.appInformation?.supportContact && (
                              <ErrorText>
                                {errors.appInformation?.supportContact.message}
                              </ErrorText>
                            )}
                          </>
                        )}
                      />
                    </InputWrapper>
                    <StandardText color='black' textAlign='left' margin='0.5rem 0rem'>
                      URL do domínio do aplicativo:
                    </StandardText>

                    <InputWrapper>
                      <Controller
                        control={control}
                        name="appInformation.webUrl"
                        rules={{
                          validate: (value) => {
                            if (!value) {
                              return true;
                            }
                            // Caso contrário, verifica se a URL é válida
                            return isUrlValid(value) || "Insira uma URL válida";
                          },
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <TextInput
                              color="black"
                              backgroundColor="white"
                              label="Domínio do app"
                              placeholder="https://app.exemplo.com.br"
                              onBlur={onBlur}
                              onChangeText={(text) => {
                                onChange(text);
                                setWebUrl(text);
                              }}
                              value={webUrl || value}
                              error={errors.appInformation?.webUrl}
                            />
                            {errors.appInformation?.webUrl && (
                              <ErrorText>
                                {errors.appInformation.webUrl.message}
                              </ErrorText>
                            )}
                          </>
                        )}
                      />
                    </InputWrapper>
                  </FormContainer>

                  <FormContainer>
                    <StandardText color='black' textAlign='left' margin='0.5rem 0rem'>
                      Link dos termos de uso:
                    </StandardText>

                    <InputWrapper>
                      <TextInput
                        color="black"
                        backgroundColor="white"
                        label="URL dos seus termos de uso"
                        placeholder="https://exemplo.com.br"
                        onChangeText={(text) => setTermsOfUseUrl(text)}
                        value={termsOfUseUrl}
                      />
                    </InputWrapper>

                    <StandardText color='black' textAlign='left' margin='0.5rem 0rem'>
                      Link da página de vendas:
                    </StandardText>

                    <InputWrapper>
                      <TextInput
                        color="black"
                        backgroundColor="white"
                        label="URL da sua página de vendas"
                        placeholder="https://exemplo.com.br"
                        onChangeText={(text) => setSalesPageUrl(text)}
                        value={salesPageUrl}
                      />
                    </InputWrapper>

                    <StandardText color='black' textAlign='left' margin='0.5rem 0rem'>
                      Links dos Aplicativos:
                    </StandardText>

                    <InputWrapper>
                      <Controller
                        control={control}
                        name="appInformation.IOSUrl"
                        rules={{
                          validate: (value) => {
                            if (!value) {
                              return true;
                            }
                            // Caso contrário, verifica se a URL é válida
                            return isUrlValid(value) || "Insira uma URL válida";
                          },
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <TextInput
                              color="black"
                              backgroundColor="white"
                              label="URL do app na Apple"
                              placeholder="Digite a URL"
                              onBlur={onBlur}
                              onChangeText={(text) => {
                                onChange(text);
                                setIosUrl(text);
                              }}
                              value={iosUrl || value}
                              error={errors.appInformation?.IOSUrl}
                            />
                            {errors.appInformation?.IOSUrl && (
                              <ErrorText>
                                {errors.appInformation.IOSUrl.message}
                              </ErrorText>
                            )}
                          </>
                        )}
                      />
                    </InputWrapper>

                    <InputWrapper>
                      <Controller
                        control={control}
                        name="appInformation.androidUrl"
                        rules={{
                          validate: (value) => {
                            if (!value) {
                              return true;
                            }
                            // Caso contrário, verifica se a URL é válida
                            return isUrlValid(value) || "Insira uma URL válida";
                          },
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <TextInput
                              color="black"
                              backgroundColor="white"
                              label="URL do app na PlayStore"
                              placeholder="Digite a URL do app na Play Store"
                              onBlur={onBlur}
                              onChangeText={(text) => {
                                onChange(text);
                                setAndroidUrl(text);
                              }}
                              value={androidUrl || value}
                              error={errors.appInformation?.androidUrl}
                            />
                            {errors.appInformation?.androidUrl && (
                              <ErrorText>
                                {errors.appInformation.androidUrl.message}
                              </ErrorText>
                            )}
                          </>
                        )}
                      />
                    </InputWrapper>

                    {hasForm &&
                      <>
                        <StandardText color='black' textAlign='left' margin='0.5rem 0rem'>
                          Link do formulário Google:
                        </StandardText>

                        <InputWrapper>
                          <TextInput
                            color="black"
                            backgroundColor="white"
                            label="URL do formulário do Google"
                            placeholder="https://docs.google.com/forms/"
                            onChangeText={(text) => setFormEmbed(text)}
                            value={formEmbed}
                          />
                        </InputWrapper>
                      </>
                    }

                    {hasIAP &&
                      <View
                        style={{
                          alignItems: "center",
                          flexDirection: "row",
                          marginBottom: 6,
                          gap: 6,
                        }}
                      >
                        <SmallText color='black' textAlign='left'>
                          Habilitar loja online?
                        </SmallText>
                        <Switch
                          value={hasMyStore}
                          onValueChange={(value) => {
                            if (value === false) {
                              setValue("hasAppInfo", false);
                              setHasMyStore(false);
                            } else {
                              setHasMyStore(value);
                            }
                          }}
                        />
                        {hasMyStore && (
                          <TextInput
                            color="black"
                            backgroundColor="white"
                            label="URL da sua loja online"
                            placeholder="https://exemplo.com.br"
                            onChangeText={(text) => setMyStoreUrl(text)}
                            value={myStoreUrl}
                            width="80%"
                          />

                        )}
                      </View>
                    }
                  </FormContainer>
                </MobileOrDesktopForm>
                <Button
                  title="Salvar"
                  onPress={handleSubmit(onSubmit)}
                  isLoading={isPendingUpdatedUserInfo}
                  style={{ cursor: "pointer", alignSelf: "center" }}
                />
              </>
            )}
          </Box>
        </MobileOrDesktopContainer>
      </Container>
      <Toast config={toastConfig} />
    </ViewportProvider>
  );
}
