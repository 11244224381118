import React, { useState, useEffect } from 'react';
import { View, Text, ActivityIndicator, StyleSheet, Linking, TouchableOpacity } from 'react-native';
import { createCheckout } from "../AdminPanel/Products/service/product-service";
import { SmallText, StandardText } from "../../config/theme/globalStyles";
import Button from "../../components/Button";
import { CommonActions } from "@react-navigation/native";

const PostCheckoutProduct = ({ route, navigation }) => {
  const { data, productData } = route.params;
  const [loading, setLoading] = useState(true);
  const [paymentUrl, setPaymentUrl] = useState(null);
  const [subscriptionRes, setSubscriptionRes] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    createCheckout({ ...productData, ...data })
      .then(res => {
        if (productData.type !== 'plan') {
          const url = res?.data?.body?.checkouts[0]?.payment_url;
          if (url) {
            setPaymentUrl(url);
            Linking.openURL(url).catch(err => console.error('Failed to open URL:', err));
          } else {
            setError("Erro durante o fluxo de compra. Entre em contato com o suporte.");
          }
        } else {
          if (res?.data?.status !== 200) {
            setError("Erro durante o fluxo de compra. Confira seus dados e tente novamente.");
          } else {
            setSubscriptionRes(res);
          }
        }
      })
      .catch(err => {
        setError("Erro durante o fluxo de compra, por favor, entre em contato com o suporte.");
      })
      .finally(() => setLoading(false));
  }, [productData, data]);

  const getStatusComponent = () => {
    if (error) {
      return (
        <StandardText style={styles.text}>
          {error}{' '}
        </StandardText>
      );
    }

    if (paymentUrl) {
      return (
        <StandardText style={styles.text}>
          Você será redirecionado para a página de compra, caso isso não aconteça{' '}
          <TouchableOpacity onPress={() => Linking.openURL(paymentUrl)}>
            <Text style={styles.link}>clique aqui</Text>
          </TouchableOpacity>
        </StandardText>
      );
    }

    if (subscriptionRes) {
      return (
        <>
          <StandardText style={[styles.text, { marginVertical: 10 }]}>
            Assinatura efetuada com sucesso!
          </StandardText>
          <SmallText style={{ marginBottom: 30 }}>
            Em caso de primeira compra, verifique seu e-mail para obter as credenciais de acesso.
          </SmallText>
          <Button
            title="Fazer Login"
            onPress={() =>
              navigation.dispatch(
                CommonActions.reset({
                  index: 1,
                  routes: [{ name: 'Login' }],
                })
              )
            }
          />
        </>
      );
    }

    return (
      <StandardText style={styles.text}>
        Erro durante o fluxo de compra, por favor, confira seus dados e tente novamente.
      </StandardText>
    );
  };

  if (loading) {
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  return (
    <View style={styles.container}>
      {getStatusComponent()}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: 16,
    textAlign: 'center',
  },
  link: {
    color: 'blue',
    textDecorationLine: 'underline',
  },
});

export default PostCheckoutProduct;
