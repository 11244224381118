import React from 'react';
import { Modal, Text, Linking, TouchableOpacity, Image } from 'react-native';
import { SubTitle } from '../../config/theme/globalStyles';
import Button from '../Button';
import THEME from '../../config/theme';
import * as S from "./style";
import { useCustomTheme } from '../../contexts/useCustomTheme';
import { removeSansSerif } from '../../utils/validators';

const GenericModal = ({
  isOpen,
  onClose,
  title,
  message,
  appleLink,
  androidLink,
  buttonText,
  onConfirm,
  isLoading = false,
}) => {
  const { customColor, customFont } = useCustomTheme();

  const handleAppleDownload = () => {
    onClose(); // Fecha o modal antes de redirecionar
    Linking.openURL(appleLink); // Abre o link da App Store
  };

  const handleAndroidDownload = () => {
    onClose(); // Fecha o modal antes de redirecionar
    Linking.openURL(androidLink); // Abre o link do Google Play
  };

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={isOpen}
      onRequestClose={onClose}
    >
      <S.Container>
        <S.ModalView>
          <S.StyledButton onPress={onClose}>
            <Text fontSize={18} color='black'>X</Text>
          </S.StyledButton>

          <SubTitle
            padding="0rem 0rem 0.5rem 0rem"
            color={customColor?.text?.auth || THEME.COLORS.TEXT_ABOUT}
            fontFamily={
              customFont?.fontFamilyTitle ? removeSansSerif(customFont.fontFamilyTitle) : THEME.FONTFAMILY.MEDIUM
            }
          >
            {title}
          </SubTitle>

          {message && (
            <S.InfoMessage
              style={{
                color: customColor?.text?.auth || THEME.COLORS.TEXT_ABOUT,
                fontFamily: customFont?.fontFamilyTitle
                  ? removeSansSerif(customFont.fontFamilyTitle)
                  : THEME.FONTFAMILY.MEDIUM,
              }}
            >
              {message}
            </S.InfoMessage>
          )}

          <S.ButtonContainer>
            {appleLink && androidLink ? (
              <>
                <TouchableOpacity
                  onPress={handleAppleDownload}>
                  <Image
                    source={"https://upload.wikimedia.org/wikipedia/commons/thumb/5/5d/Available_on_the_App_Store_%28black%29.png/320px-Available_on_the_App_Store_%28black%29.png"}
                    style={{ width: 150, height: 46, resizeMode: "cover", marginVertical: 10 }}
                  />
                </TouchableOpacity>
                <TouchableOpacity
                  onPress={handleAndroidDownload}>
                  <Image
                    source={"https://upload.wikimedia.org/wikipedia/commons/thumb/7/78/Google_Play_Store_badge_EN.svg/320px-Google_Play_Store_badge_EN.svg.png"}
                    style={{ width: 150, height: 46, resizeMode: "cover", marginVertical: 10 }}
                  />
                </TouchableOpacity>
              </>
            ) : (
              <Button
                title={buttonText}
                onPress={() => {
                  onConfirm();
                  onClose();
                }}
                isLoading={isLoading}
                fontFamily={
                  customFont?.fontFamilyText
                    ? removeSansSerif(customFont.fontFamilyText)
                    : THEME.FONTFAMILY.MEDIUM
                }
              />
            )}
          </S.ButtonContainer>
        </S.ModalView>
      </S.Container>
    </Modal>
  );
};

export default GenericModal;