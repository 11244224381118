import styled from "styled-components/native";
import THEME from "../../../config/theme";
import { Dimensions } from 'react-native';
const { width: screenWidth, height: windowHeight } = Dimensions.get("window");

export const ModalBackground = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const ModalContainer = styled.View`
  border-radius: 10px;
  background-color: white;
  align-items: center;
  width: ${screenWidth > 980 ? screenWidth * 0.6 : screenWidth * 0.9}px;
  max-height: ${windowHeight * 0.8}px;
`;

export const Header = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: #d3d3d3;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 10px;
`;

export const CloseButton = styled.TouchableOpacity`
  padding: 10px;
`;

export const Content = styled.View`
  width: 100%;
  padding: 0 10px;
`;

export const ScrollContainer = styled.ScrollView`
  width: 100%;
  height: 100%;
`;

export const ViewSectionAdmin = styled.View`
 padding: 1rem 0rem;
 flex-basis: auto;
 background-color: ${THEME.COLORS.BACKGROUND_ABOUT};
`;


export const ViewButton = styled.View`
 padding: 0.5rem 0rem;
 align-items: center;
 justify-content: center;
 flex-direction: row;
`;


export const ViewTextInput = styled.View`
padding: 0.2rem;
 `;

export const ViewRow = styled.View`
flex-direction: row;
align-items: center;
 `;


export const ViewDataTable = styled.View`
 padding-horizontal: 30px;
`;


export const ViewModal = styled.ScrollView.attrs({
  showsVerticalScrollIndicator: true,
  contentContainerStyle: {
    alignItems: "center",
    padding: "2rem",
    backgroundColor: "#FFFFFF",
    justifyContent: "center",
    flex: 1
  },
})` 
`;

export const ViewSwitch = styled.View`
 align-items: center;
 justify-content: center;
 flex-direction: row;
`;

export const ViewDescription = styled.View`
 justify-content: center;
 padding: 1rem 0rem 0rem 0rem;
`;

export const ContentIcon = styled.View`
 position: absolute;
 bottom: 20px;
 right: 20px;
 background-color: #3FC380E6;
 border-radius: 50px;
 padding: 5px;
 z-index: 99999;
 width: 50px;
 height: 50px;
 justify-content: center;
 align-items: center;
 border-color: #FFFFFF;
 border-width: 2px;
`;

export const Container = styled.ScrollView.attrs({
  showsVerticalScrollIndicator: true,
  contentContainerStyle: {
    flexGrow: 1,
    backgroundColor: "#d3d3d3"
  },
})``;