import React, { useState, useCallback, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import { useNavigation } from "@react-navigation/native";
import Constants from "expo-constants";
import { ActivityIndicator, Modal, Portal, Provider } from "react-native-paper";
import { ViewHorizontal, Row, ViewTable, ContainerScroll } from "./styles";
import { Title, SmallText, SubTitle, StandardText, FooterText } from '../../../config/theme/globalStyles';
import THEME from '../../../config/theme';
import Button from '../../../components/Button';
import TextInput from '../../../components/TextInput';
import TouchableText from '../../../components/TouchableText';
import CreateUserModal from "../../AdminPanel/AppUsers/CreateUserModal";
import { ResetPassword } from "../../Login/ResetPassword";
import { TextInput as Input } from 'react-native-paper';
import View from '@expo/html-elements/build/primitives/View';
import { firestore } from "../../../services/firebase";
import PQueue from 'p-queue';
import { doc, getDoc, collection, getDocs, query, where, limit } from "firebase/firestore";

import { formatDateBR } from '../../../utils/dateUtils';
import { generateCSVContent } from '../../../utils/csvUtils';
import { getPlatformForProduct, getProductName } from '../../../utils/accessUtils';

const Table = ({
    tableData,
    products,
    totalCount,
    loading,
    totalFiltered,
    handleSaveUser,
    handlePageChange,
    handleFilter,
    resetPaginationToggle,
    fetchAllUsersHandler
}) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [isCreateUserModalVisible, setIsCreateUserModalVisible] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [visible, setVisible] = useState(false);
    const [isDownloadAllModalVisible, setIsDownloadAllModalVisible] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const { guruUserToken, pagarmeApiKey } = Constants.manifest.extra;
    const navigation = useNavigation();

    const [productsCache, setProductsCache] = useState({});

    // Função para buscar o nome do produto Guru na coleção 'products'
    const fetchProductName = useCallback(
        async (productId) => {
            // Se já existe no cache, retorna imediatamente
            if (productsCache[productId]) {
                return productsCache[productId];
            }

            let nameFound = productId; // fallback se não achar nada

            try {
                // Verifica se é Guru (ID numérico)
                if (/^\d+$/.test(productId)) {
                    // GURU => query por productId == productId
                    const q = query(
                        collection(firestore, 'products'),
                        where('productId', '==', productId),
                        limit(1)
                    );
                    const querySnapshot = await getDocs(q);
                    if (!querySnapshot.empty) {
                        const docData = querySnapshot.docs[0].data();
                        nameFound = docData?.name || productId;
                    }
                }
                // Verifica se é Pagarme (20 caracteres alfanuméricos)
                else if (/^[A-Za-z0-9]{20}$/.test(productId)) {
                    // PAGARME => docRef = doc(firestore, 'products', productId)
                    const docRef = doc(firestore, 'products', productId);
                    const docSnap = await getDoc(docRef);
                    if (docSnap.exists()) {
                        const docData = docSnap.data();
                        nameFound = docData?.name || productId;
                    }
                }
                // Se não for nem Guru nem Pagarme, fica com o fallback

            } catch (error) {
                console.error("Erro ao buscar nome do produto:", error);
            }

            // Salva no cache para evitar buscas repetidas
            setProductsCache((prev) => ({
                ...prev,
                [productId]: nameFound,
            }));

            return nameFound;
        },
        [productsCache]
    );

    // Função que define a forma de cadastro
    const formatAccess = useCallback((row) => {
        return row?.isSignUpFromApp
            ? "Aplicativo Mobile"
            : row?.isSignUpFromAdminPanel
                ? "Manualmente"
                : "Aplicativo Web";
    }, []);

    // Verificações de validade de acesso
    const hasValidPlan = useCallback((row) => row.planTitle && row.plan !== "0", []);

    const hasValidProduct = useCallback((row) => {
        return row.productIds?.some(product => {
            const expirationDate = product.expirationDate instanceof Date
                ? product.expirationDate
                : product.expirationDate?.toDate?.();
            return product.productId && (!expirationDate || expirationDate > new Date());
        });
    }, []);

    const hasValidCourse = useCallback((row) => {
        return row.courses?.some(course => {
            const expirationDate = course.dueDate instanceof Date
                ? course.dueDate
                : course.dueDate?.toDate?.();
            return expirationDate > new Date();
        });
    }, []);

    // Função que define o status de acesso geral
    const getAccessStatus = useCallback((row, productIndex = null, forCSV = false) => {
        if (hasValidPlan(row) || hasValidProduct(row) || hasValidCourse(row)) {
            return 'Ativo';
        }
        if (forCSV && productIndex !== null) {
            const now = new Date();
            const product = row.productIds?.[productIndex] || {};
            const course = row.courses?.[productIndex] || {};
            const expirationDate = product.expirationDate?.toDate
                ? product.expirationDate.toDate()
                : course.dueDate?.toDate
                    ? course.dueDate.toDate()
                    : null;
            if ((product.transactionIdentifier || product.productId) && !expirationDate) {
                return 'Ativo';
            }
            return expirationDate > now ? 'Ativo' : 'Acesso expirado';
        }
        return 'Sem compra ativa';
    }, [hasValidPlan, hasValidProduct, hasValidCourse]);

    // Agrega informações de acesso
    const getProductAccess = useCallback((row, forCSV = false) => {
        const accessInfo = [];

        if (hasValidPlan(row)) {
            const accessEntry = forCSV
                ? { type: "Plano Stripe", name: row.planTitle, dueDate: null }
                : `Stripe - ${row.planTitle}`;
            accessInfo.push(accessEntry);
        }

        if (row.coursesTitle?.length > 0) {
            accessInfo.push(...row.coursesTitle.map((course, index) => {
                const courseData = row.courses?.[index] || {};
                return forCSV
                    ? { type: "Curso Stripe", name: course, dueDate: courseData.dueDate ? formatDateBR(courseData.dueDate) : null }
                    : `Stripe - ${course}`;
            }));
        }

        if (row.productIds?.length > 0) {
            accessInfo.push(...row.productIds.map(product => {
                const productName = getProductName(product.productId);
                return forCSV
                    ? { type: "", name: productName, dueDate: product.expirationDate ? product.expirationDate.toDate() : null }
                    : productName;
            }));
        }

        return forCSV ? accessInfo : (accessInfo.length ? accessInfo.join(' / ') : '-');
    }, [hasValidPlan]);

    const productAccess = useCallback((row) => getProductAccess(row, false), [getProductAccess]);
    const productAccessForCSV = useCallback((row) => getProductAccess(row, true), [getProductAccess]);

    // Busca informações de plataforma e data de compra
    const fetchPlatformAndDate = useCallback(async (userDoc, product) => {
        try {
            let platform = "-";
            let currentDataCycle = "-";

            if (!product.transactionIdentifier) {
                return { platform: getPlatformForProduct(product), currentDataCycle };
            } else {
                const docRef = doc(firestore, 'users', userDoc.id, 'subscriptions', product.transactionIdentifier);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const subData = docSnap.data();
                    platform = subData.store === "PLAY_STORE"
                        ? "Play Store"
                        : subData.store === "APP_STORE"
                            ? "Apple Store"
                            : "-";
                    currentDataCycle = subData.purchased_at_ms
                        ? formatDateBR(new Date(subData.purchased_at_ms))
                        : "-";
                }
            }
            return { platform, currentDataCycle };
        } catch (error) {
            console.error("Erro ao buscar dados da compra:", error);
            return { platform: "-", currentDataCycle: "-" };
        }
    }, []);

    const showModal = useCallback(() => setVisible(true), []);
    const hideModal = useCallback(() => setVisible(false), []);

    const containerStyle = useMemo(() => ({
        backgroundColor: "white",
        padding: "2rem",
        width: Math.round(window.innerWidth * 0.7),
        alignSelf: "center",
    }), []);

    // Definição das colunas da tabela
    const columns = useMemo(() => [
        { name: 'Nome', selector: row => row.Nome_Completo || '', sortable: true },
        { name: 'Email', selector: row => row.Email || '', sortable: true },
        { name: 'Status', selector: row => getAccessStatus(row), sortable: true },
        { name: 'Acessos', selector: productAccess, sortable: true },
        {
            name: 'Vídeos assistidos',
            selector: row => row.watchedVideos?.length || 0,
            cell: row => (
                <ViewHorizontal>
                    <SmallText color="black">{row.watchedVideos?.length || 0}</SmallText>
                    {row.watchedVideos?.length > 0 && (
                        <TouchableText
                            onPress={() => navigation.navigate("UsersAnalytics", { userId: row.id, userName: row.Nome_Completo || '' })}
                            title="Ver"
                            textDecoration="underline"
                            color="#767577"
                            margin="0rem 1rem"
                        />
                    )}
                </ViewHorizontal>
            ),
            sortable: true,
        },
        { name: 'Forma de Cadastro', selector: formatAccess, sortable: true },
        ...(guruUserToken ? [{
            name: 'Ações',
            cell: row => (
                <TouchableText
                    onPress={() => navigation.navigate("MeuPerfil", { userId: row.id, editUserAccess: true })}
                    title="Editar acesso"
                    fontFamily={THEME.FONTFAMILY.LIGHT}
                    fontSize={THEME.FONTSIZE.EXTRASMALL}
                    textDecoration="underline"
                    color="black"
                />
            ),
            sortable: true,
        }] : []),
    ], [getAccessStatus, productAccess, formatAccess, guruUserToken, navigation]);

    const customStyles = useMemo(() => ({
        rows: { style: { color: "#000000", fontFamily: THEME.FONTFAMILY.LIGHT, fontSize: THEME.FONTSIZE.EXTRASMALL } },
        headCells: { style: { color: "#000000", fontFamily: THEME.FONTFAMILY.MEDIUM, fontSize: THEME.FONTSIZE.EXTRASMALL } },
    }), []);

    // Processa os dados para geração do CSV, tratando cada acesso individualmente
    const handleDownloadCSV = useCallback(async (rows) => {
        const headers = [
            'Nome_Completo', 'Email', 'Celular', 'CPF', 'Tipo_De_Acesso',
            'Acesso', 'Plataforma', 'Data_Ciclo_Atual', 'Data_Validade_Acesso',
            'Vídeos_Assistidos', 'Data_de_Cadastro', 'Forma_de_Cadastro'
        ];

        const selectedData = [];

        for (const row of rows) {
            const userData = { id: row.id, data: () => row };
            const accesses = productAccessForCSV(row);

            if (accesses.length === 0) {
                selectedData.push({
                    Nome_Completo: row.Nome_Completo || '-',
                    Email: row.Email || '-',
                    Celular: row.Celular || '-',
                    CPF: row?.CPF || row?.Doc || '-',
                    Tipo_de_Acesso: 'Sem compras',
                    Acesso: '-',
                    Plataforma: '-',
                    Data_Ciclo_Atual: '-',
                    Data_Validade_Acesso: '-',
                    Vídeos_Assistidos: row.watchedVideos ? row.watchedVideos.length : 0,
                    Data_de_Cadastro: formatDateBR(row?.created_at) || '-',
                    Forma_de_Cadastro: formatAccess(row) || '-',
                });
            } else {
                for (let i = 0; i < accesses.length; i++) {
                    const access = accesses[i];
                    let statusAccess = '';
                    let validade = access.dueDate ? access.dueDate : null;
                    let platform = "-";
                    let currentDataCycle = "-";

                    if (access.type === "Plano Stripe") {
                        statusAccess = 'Ativo';
                        platform = "Stripe";
                    } else if (access.type === "Curso Stripe") {
                        const now = new Date();
                        let validadeDate = null;
                        if (typeof validade === 'string') {
                            const [day, month, year] = validade.split('/');
                            validadeDate = new Date(`${year}-${month}-${day}T23:59:59`);
                        } else {
                            validadeDate = validade;
                        }
                        statusAccess = validadeDate && validadeDate > now ? 'Ativo' : 'Acesso expirado';
                        platform = "Stripe";
                    } else {
                        // Acesso Guru ou IAP
                        const productIndex = i - ((row.coursesTitle?.length || 0) + (row.planTitle ? 1 : 0));
                        const product = row.productIds?.[productIndex] || {};
                        const now = new Date();

                        if ((product.productId || product.transactionIdentifier) && !validade) {
                            statusAccess = 'Ativo';
                        } else {
                            statusAccess = validade && validade > now ? 'Ativo' : 'Acesso expirado';
                        }

                        // Busca plataforma (Guru, Pagarme, etc.) e data de compra
                        const { platform: fetchedPlatform, currentDataCycle: fetchedPurchaseDate } =
                            await fetchPlatformAndDate(userData, product);
                        platform = fetchedPlatform || "-";
                        currentDataCycle = fetchedPurchaseDate || "-";
                    }

                    // Se "access.name" for numérico (Guru) ou 20 chars (Pagarme), chamamos fetchProductName
                    let productName = access.name || '-';
                    const isGuru = /^\d+$/.test(productName);
                    const isPagarme = /^[A-Za-z0-9]{20}$/.test(productName);

                    if (isGuru || isPagarme) {
                        productName = await fetchProductName(productName);
                    }

                    selectedData.push({
                        Nome_Completo: row.Nome_Completo || '-',
                        Email: row.Email || '-',
                        Celular: row.Celular || '-',
                        CPF: row?.CPF || row?.Doc || '-',
                        Tipo_de_Acesso: statusAccess || '-',
                        Acesso: productName,
                        Plataforma: platform || '-',
                        Data_Ciclo_Atual: currentDataCycle || '-',
                        Data_Validade_Acesso: validade ? formatDateBR(validade) : '-',
                        Vídeos_Assistidos: row.watchedVideos ? row.watchedVideos.length : 0,
                        Data_de_Cadastro: formatDateBR(row?.created_at) || '-',
                        Forma_de_Cadastro: formatAccess(row) || '-',
                    });
                }
            }
        }

        const csvContent = generateCSVContent(headers, selectedData);
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'selected_data.csv');
        document.body.appendChild(link);
        link.click();
    }, [productAccessForCSV, fetchPlatformAndDate, formatAccess, fetchProductName]);

    const downloadAllUsers = useCallback(async () => {
        if (!isDownloading) {
            setIsDownloadAllModalVisible(true);
            setIsDownloading(true);
            const queue = new PQueue({ concurrency: 5 });
            let allUsers = [];
            let lastVisible = null;
            const batchSize = 500;

            try {
                while (true) {
                    const batch = await queue.add(async () => {
                        return fetchAllUsersHandler(batchSize, lastVisible);
                    });

                    if (batch.users.length === 0) break;

                    allUsers = allUsers.concat(batch.users);
                    lastVisible = batch.lastVisible;

                    if (batch.users.length < batchSize) break;
                }
                await handleDownloadCSV(allUsers);
            } catch (error) {
                console.error("Erro ao baixar todos os usuários:", error);
                alert("Ocorreu um erro ao baixar os usuários.");
            } finally {
                setIsDownloading(false);
                setIsDownloadAllModalVisible(false);
            }
        } else {
            alert("Arquivo está sendo processado");
        }
    }, [isDownloading, fetchAllUsersHandler, handleDownloadCSV]);

    const paginationComponentOptions = useMemo(() => ({
        rowsPerPageText: 'Filas por página',
        rangeSeparatorText: 'de',
    }), []);

    const ExpandedComponent = useCallback(({ data }) => (
        <ViewTable>
            <SmallText textAlign="left" color="#2e2e2e" margin="0.5rem 0rem">
                Dados gerais:
            </SmallText>
            <FooterText textAlign="left" color="#2e2e2e">
                Celular: {data?.Celular || 'não informado'}
            </FooterText>
            <FooterText textAlign="left" color="#2e2e2e">
                CPF: {data?.CPF || data?.Doc || 'não informado'}
            </FooterText>
            {data?.created_at && (
                <FooterText textAlign="left" color="#2e2e2e">
                    Data de cadastro: {formatDateBR(data?.created_at)}
                </FooterText>
            )}
            {data?.productIds && data?.productIds.length > 0 && (
                <>
                    <SmallText textAlign="left" color="#2e2e2e" margin="0.5rem 0rem">
                        Produtos com acesso:
                    </SmallText>
                    {data.productIds.map((product, index) => (
                        <FooterText textAlign="left" color="#2e2e2e" key={index}>
                            {product.productId} {product?.expirationDate ? `- Expira em: ${formatDateBR(product?.expirationDate)}` : ''}
                        </FooterText>
                    ))}
                </>
            )}
            {data?.planTitle && data?.plan !== "0" && (
                <>
                    <SmallText textAlign="left" color="#2e2e2e" margin="0.5rem 0rem">
                        Planos da Stripe:
                    </SmallText>
                    <FooterText textAlign="left" color="#2e2e2e">
                        {data.planTitle}
                    </FooterText>
                </>
            )}
            {data?.coursesTitle && data?.courses && (
                <>
                    <SmallText textAlign="left" color="#2e2e2e" margin="0.5rem 0rem">
                        Cursos da Stripe:
                    </SmallText>
                    {data.coursesTitle.map((course, index) => (
                        <FooterText textAlign="left" color="#2e2e2e" key={index} style={{ display: "inline" }}>
                            {course}
                            {data.courses[index]?.dueDate
                                ? ` - Expira em: ${formatDateBR(data.courses[index].dueDate)}`
                                : ''}
                            {index < data.coursesTitle.length - 1 ? ' | ' : ''}
                        </FooterText>
                    ))}
                </>
            )}
        </ViewTable>
    ), []);

    return (
        <Provider>
            <ContainerScroll>
                <ViewHorizontal>
                    <Title textAlign="left" color="black">Alunos:</Title>
                    <Row gap="1rem">
                        <Button
                            onPress={downloadAllUsers}
                            title={"Baixar CSV completo"}
                            fontFamily={THEME.FONTFAMILY.LIGHT}
                            fontSize={THEME.FONTSIZE.EXTRASMALL}
                            colorbutton="#a6a5a4"
                            colortitle="#FFFFFF"
                        />
                        <Button
                            onPress={() => handleDownloadCSV(selectedRows)}
                            title={"Baixar CSV"}
                            fontFamily={THEME.FONTFAMILY.LIGHT}
                            fontSize={THEME.FONTSIZE.EXTRASMALL}
                            colorbutton="#a6a5a4"
                            colortitle="#FFFFFF"
                        />
                        {(guruUserToken || pagarmeApiKey) &&
                            <Button
                                onPress={() => setIsCreateUserModalVisible(true)}
                                title={"+ Adicionar"}
                                fontFamily={THEME.FONTFAMILY.LIGHT}
                                fontSize={THEME.FONTSIZE.EXTRASMALL}
                                colorbutton="#a6a5a4"
                                colortitle="#FFFFFF"
                            />
                        }
                        <Button
                            onPress={showModal}
                            title={"Redefinir senha"}
                            fontFamily={THEME.FONTFAMILY.LIGHT}
                            fontSize={THEME.FONTSIZE.EXTRASMALL}
                            colorbutton="#a6a5a4"
                            colortitle="#FFFFFF"
                        />
                    </Row>
                </ViewHorizontal>
                <ViewHorizontal>
                    {(guruUserToken || pagarmeApiKey) &&
                        <SmallText textAlign="left" color="#2e2e2e">
                            Aqui você verá uma lista de todos os alunos que se cadastraram no seu aplicativo pelo formulário de cadastro do app Mobile (publicado na Apple Store e Google Play) ou que administradores deram acesso manualmente. Ou seja, eles podem só ter se cadastrado ou podem já ter efetuado compras. Para gestão das suas vendas e envio de e-mails a essas pessoas, baixe essa planilha no botão "baixar csv" e importe ela na sua plataforma de e-mail marketing.
                        </SmallText>
                    }
                </ViewHorizontal>
                <ViewHorizontal justifyContent="space-evenly">
                    <ViewTable>
                        <StandardText color="black">Alunos cadastrados</StandardText>
                        <SubTitle color="#767577">{totalCount}</SubTitle>
                    </ViewTable>
                </ViewHorizontal>
                <ViewHorizontal justifyContent="space-evenly">
                    <TextInput
                        color="black"
                        backgroundColor="white"
                        width="80%"
                        placeholder="Pesquisar por email completo"
                        value={searchText}
                        onChangeText={text => setSearchText(text)}
                        onSubmitEditing={(event) => {
                            setSearchText(event.nativeEvent.text);
                            handleFilter(event.nativeEvent.text);
                        }}
                        right={searchText !== '' && <Input.Icon
                            icon="alpha-x-circle-outline"
                            onPress={() => {
                                setSearchText('');
                                handleFilter('');
                            }}
                        />}
                    />
                    <Button
                        onPress={() => handleFilter(searchText)}
                        title={"Buscar"}
                        fontFamily={THEME.FONTFAMILY.LIGHT}
                        fontSize={THEME.FONTSIZE.EXTRASMALL}
                    />
                </ViewHorizontal>
                <DataTable
                    columns={columns}
                    data={tableData}
                    highlightOnHover
                    pointerOnHover
                    selectableRows
                    selectableRowsHighlight
                    selectableRowsVisibleOnly
                    customStyles={customStyles}
                    onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
                    onChangePage={handlePageChange}
                    pagination
                    paginationRowsPerPageOptions={[10]}
                    paginationServer
                    progressPending={loading}
                    progressComponent={
                        <ActivityIndicator
                            style={{
                                flex: 1,
                                backgroundColor: "#FFFFFF",
                                justifyContent: "center",
                            }}
                            color="#d3d3d3"
                            size="large"
                        />
                    }
                    paginationDefaultPage={1}
                    paginationResetDefaultPage={resetPaginationToggle}
                    paginationTotalRows={totalFiltered}
                    paginationComponentOptions={paginationComponentOptions}
                    paginationIconFirstPage={false}
                    paginationIconLastPage={false}
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                />
                {isCreateUserModalVisible && products &&
                    <CreateUserModal
                        products={products}
                        visible={isCreateUserModalVisible}
                        onClose={() => setIsCreateUserModalVisible(false)}
                        onSave={handleSaveUser}
                    />
                }
                <Modal
                    visible={isDownloadAllModalVisible}
                    contentContainerStyle={containerStyle}
                >
                    <View>
                        <ActivityIndicator
                            style={{ flex: 1, backgroundColor: "#FFFFFF", justifyContent: "center" }}
                            color="#666666"
                            size="large"
                        />
                        <br />
                        <br />
                        <SmallText color="black">
                            Processando arquivo, aguarde alguns minutos...
                        </SmallText>
                    </View>
                </Modal>
                <Portal>
                    <Modal
                        visible={visible}
                        onDismiss={hideModal}
                        contentContainerStyle={containerStyle}
                    >
                        <ResetPassword isAdmin={true} />
                    </Modal>
                </Portal>
            </ContainerScroll>
        </Provider>
    );
};

export default Table;