import React, { useState, useEffect } from "react";
import { Provider } from "react-native-paper";
import Header from "../../components/Header";
import { getAuth, onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { doc, setDoc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import { firestore } from "../../services/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  Content,
  ViewText,
  ViewTextInput,
  ViewDescription,
  ViewButton,
  ViewPlan,
  ViewHelper,
  VerticalScroll,
  ViewModal,
  ViewRow
} from "./style";
import {
  Container,
  SmallText,
  StandardText,
} from "../../config/theme/globalStyles";
import TextInput from "../../components/TextInput";
import Button from "../../components/Button";
import THEME from "../../config/theme";
import { AlertBox } from "../../components/AlertBox";
import { nameValidator, cellphoneValidator, removeSansSerif } from "../../utils/validators";
import { HelperText } from "react-native-paper";
import { SafeAreaView, ActivityIndicator, Modal, View, StyleSheet, TouchableOpacity } from "react-native";
import { GuruSSO } from "../../services/guru";
import Constants from 'expo-constants';
import DataTable from 'react-data-table-component';
import { Calendar } from 'react-native-calendars';
import { Feather } from '@expo/vector-icons';
import { useCustomTheme } from "../../contexts/useCustomTheme";

const auth = getAuth();
const functions = getFunctions();

export function EditProfile({ navigation, userId, editUserAccess }) {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingPlan, setLoadingPlan] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [errorCellphone, setErrorCellphone] = useState("");
  const [errorName, setErrorName] = useState("");
  const [message, setMessage] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isCalendarVisible, setCalendarVisible] = useState(false);
  const [newExpirationDate, setNewExpirationDate] = useState(null);

  const { guruUserToken } = Constants.manifest.extra;
  const { customColor, customFont } = useCustomTheme();


  useEffect(() => {
    const fetchProducts = async () => {
      const productsCollection = collection(firestore, 'products');
      const q = query(productsCollection, where("marketplace", "==", "Guru Digital"));
      const querySnapshot = await getDocs(q);
      const products = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        selected: false,
      }));
      setProducts(products);
    };
    if (userId) {
      fetchProducts();
    }
  }, [userId]);

  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  };

  const customStyles = {
    rows: {
      style: {
        color: "#000000",
        fontFamily: THEME.FONTFAMILY.LIGHT,
        fontSize: THEME.FONTSIZE.EXTRASMALL
      },
    },
    headCells: {
      style: {
        color: "#000000",
        fontFamily: THEME.FONTFAMILY.MEDIUM,
        fontSize: THEME.FONTSIZE.EXTRASMALL
      },
    },
  };

  const columns = [
    {
      name: 'Produto Guru',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Acesso',
      selector: row => {
        if (row.hasAccess) {
          if (row.originalExpirationDate === null) {
            return 'Acesso concedido';
          } else if (row.originalExpirationDate > new Date()) {
            return 'Acesso concedido';
          } else {
            return 'Sem acesso';
          }
        } else {
          return 'Sem acesso';
        }
      },
      sortable: true,
    },
    {
      name: 'Validade de acesso',
      cell: row => (
        row.expirationDate ? (
          <>
            <SmallText color="black">{row.expirationDate}</SmallText>
            <TouchableOpacity onPress={() => handleDateEdit(row.productId, row.expirationDate)}>
              <Feather name="edit-3" size={24} color="black" />
            </TouchableOpacity>
          </>
        ) : (
          <SmallText color="black">-</SmallText>
        )
      ),
    },
    {
      name: 'Edição',
      cell: row => row.hasAccess && row.originalExpirationDate > new Date() ? (
        <Button
          onPress={() => handleRemoveAccess(row.productId, row.expirationDate)}
          title={"Remover acesso"}
          fontFamily={THEME.FONTFAMILY.LIGHT}
          fontSize={THEME.FONTSIZE.EXTRASMALL}
          colorbutton={"#FA5D5D"}
          colortitle="#000000"
        />
      ) : (
        <Button
          onPress={() => handleAddAccess(row.productId)}
          title={"Add acesso manual"}
          fontFamily={THEME.FONTFAMILY.LIGHT}
          fontSize={THEME.FONTSIZE.EXTRASMALL}
          colorbutton={"#3FC380E6"}
          colortitle="#ffffff"
        />
      )
    },
  ];

  const renderArrow = (direction) => {
    return (
      <SmallText color="black">{direction === 'left' ? '<' : '>'}</SmallText>
    );
  };

  const getUserById = async (uid) => {
    if (!uid) return;
    const docRef = doc(firestore, "users", uid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setUser({
        id: uid,
        ...docSnap.data(),
      });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (userId) {
      getUserById(userId);
    }
  }, [userId]);

  const { Nome_Completo, Email, Celular, CPF, Doc, productIds: userProductIds } = user || {};

  const showAlert = (message) => {
    setVisibleAlert(true);
    setMessage(message);
  };

  const hideAlert = (status) => {
    setVisibleAlert(status);
  };

  const validation = () => {
    let error = false;
    if (!editUserAccess) {
      const nameError = nameValidator(Nome_Completo);
      const cellphoneError = cellphoneValidator(Celular);

      if (nameError || cellphoneError) {
        error = true;
        setErrorName(nameError);
        setErrorCellphone(cellphoneError);
        setLoadingSave(false);
      }
      return error;
    }
  };

  const getUser = async () => {
    const id = auth?.currentUser?.uid;
    if (!id) return;
    await getUserById(id);
  };

  useEffect(() => {
    if (!userId) {
      onAuthStateChanged(auth, async (user) => {
        if (user && !user.isAnonymous) {
          await getUser();
        } else if (!user) {
          signInAnonymously(auth)
            .then(() => {
              setIsLoading(false);
            })
            .catch((error) => {
              console.error(error);
              setIsLoading(false);
            });
        } else {
          setIsLoading(false);
        }
      });
    }
  }, [userId]);


  if (isLoading) {
    return (
      <ActivityIndicator
        style={{
          flex: 1,
          backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
          justifyContent: "center",
        }}
        color={THEME.COLORS.PRIMARY_800}
      />
    );
  }

  async function sendToCustomerPortal() {
    const functionRef = httpsCallable(functions, "ext-firestore-stripe-payments-createPortalLink");
    setLoadingPlan(true);
    try {
      const { data } = await functionRef({
        returnUrl: window.location.origin,
        locale: "auto",
      });
      window.location.assign(data.url);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPlan(false);
    }
  }

  async function sendToCustomerPortalOfGuru() {
    setLoadingPlan(true);
    try {
      await GuruSSO();
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingPlan(false);
    }
  }

  const handleSave = () => {
    if (!validation()) {
      setLoadingSave(true);
      const userRef = doc(firestore, "users", user.id);
      setDoc(
        userRef,
        {
          Nome_Completo,
          Celular,
        },
        { merge: true }
      )
        .then(() => {
          showAlert("Concluído! Seus dados foram salvos com sucesso!");
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoadingSave(false);
        });
    }
  };

  const handleRemoveAccess = async (productId, expirationDate) => {

    const updatedUserProducts = user.productIds.filter(userProduct => userProduct.productId !== productId);
    const hasExpiratioDate = !!expirationDate

    if (productId, hasExpiratioDate) {
      try {
        await setDoc(doc(firestore, 'users', user.id), { productIds: updatedUserProducts }, { merge: true });
        location.reload();
      } catch (error) {
        showAlert("Não foi possível fazer essa operação.")
      }
    } else {
      showAlert("Não é possível remover este acesso. Gerencie pelo dashboard da Guru.");
    }
  };

  const handleAddAccess = (productId) => {
    setSelectedProduct(productId);
    setCalendarVisible(true);
  };

  const handleDateEdit = (productId, expirationDate) => {
    setSelectedProduct(productId);
    setNewExpirationDate(expirationDate);
    setCalendarVisible(true);
  };

  const handleDateConfirm = async (date) => {
    setCalendarVisible(false);

    // Ajustar a data selecionada para o fuso horário local
    const selectedDate = new Date(date);
    const adjustedDate = new Date(selectedDate.getTime() + selectedDate.getTimezoneOffset() * 60000);

    // Atualizar o usuário no Firestore com o novo acesso manual
    const userRef = doc(firestore, "users", user.id);
    const updatedUserProducts = user.productIds ? [...user.productIds] : [];

    const productIndex = updatedUserProducts.findIndex(userProduct => userProduct.productId === selectedProduct);

    if (productIndex >= 0) {
      updatedUserProducts[productIndex] = { ...updatedUserProducts[productIndex], expirationDate: adjustedDate };
    } else {
      updatedUserProducts.push({ productId: selectedProduct, expirationDate: adjustedDate });
    }

    try {
      await setDoc(userRef, { productIds: updatedUserProducts }, { merge: true });
      location.reload(); // Recarregar a página após a atualização (ou outra lógica de atualização de UI necessária)
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };


  const data = products.map(product => {
    const userProduct = userProductIds?.find(userProduct => userProduct.productId === product.productId);
    const expirationDate = userProduct?.expirationDate ? userProduct.expirationDate.toDate() : null; // Mantém a data no formato Date
    const formattedExpirationDate = expirationDate ? expirationDate.toLocaleDateString('pt-BR', options) : null; // Formata para exibição

    return {
      ...product,
      hasAccess: !!userProduct,
      expirationDate: formattedExpirationDate, // Data formatada para exibição
      originalExpirationDate: expirationDate, // Data original para lógica
    };
  });

  return (
    <Provider>
      <SafeAreaView
        style={{
          flexGrow: 1,
          backgroundColor:
            customColor && customColor.background?.auth
              ? customColor.background.auth
              : THEME.COLORS.BACKGROUND_ABOUT,
        }}
      >
        {user ? (
          <VerticalScroll
            backgroundColor={
              customColor && customColor.background?.auth
                ? customColor.background.auth
                : THEME.COLORS.BACKGROUND_ABOUT
            }
          >
            {!editUserAccess && (
              <Header
                onPressEditProfile={() => {
                  navigation.navigate('Conteudo', { screen: 'Aplicativo' });
                }}
                user={user}
              />
            )}
            {user?.plan && user?.stripeId && !editUserAccess && (
              <ViewPlan>
                <StandardText
                  style={{
                    fontSize:
                      customFont && customFont.fontMD
                        ? customFont.fontMD
                        : THEME.FONTSIZE.MEDIUM,
                  }}
                  color={
                    customColor && customColor.text && customColor.text.auth
                      ? customColor.text.auth
                      : THEME.COLORS.TEXT_ABOUT
                  }
                  fontFamily={
                    customFont && customFont.fontFamilySubtitle
                      ? removeSansSerif(customFont.fontFamilySubtitle)
                      : THEME.FONTFAMILY.MEDIUM
                  }
                  padding="0rem 0rem 0.5rem 0rem"
                  textAlign="left"
                >
                  DETALHES DO PLANO:
                </StandardText>
                <ViewButton>
                  <Button
                    title={'Editar meu plano'}
                    isLoading={loadingPlan}
                    onPress={sendToCustomerPortal}
                    borderRadius="5px"
                    colorbutton={
                      customColor &&
                        customColor.button &&
                        customColor.button.backgroundAuth
                        ? customColor.button.backgroundAuth
                        : THEME.COLORS.PRIMARY_800
                    }
                    colortitle={
                      customColor &&
                        customColor.button &&
                        customColor.button.textAuth
                        ? customColor.button.textAuth
                        : THEME.COLORS.TEXT_BUTTON
                    }
                  ></Button>
                </ViewButton>
                {user?.plan?.length === 1 && (
                  <SmallText
                    fontFamily={
                      customFont && customFont.fontFamilyText
                        ? removeSansSerif(customFont.fontFamilyText)
                        : THEME.FONTFAMILY.REGULAR
                    }
                    color={
                      customColor && customColor.text && customColor.text.auth
                        ? customColor.text.auth
                        : THEME.COLORS.TEXT_ABOUT
                    }
                    textAlign="left"
                  >
                    ATENÇÃO: Ocorreu algum erro no pagamento do seu cartão ou
                    você possui um boleto pendente! Por favor, verifique seu
                    e-mail e normalize a situação, para poder retornar o acesso
                    aos conteúdos no app.
                  </SmallText>
                )}
              </ViewPlan>
            )}
            {user?.guruId && !editUserAccess && (
              <ViewPlan>
                <ViewButton>
                  <Button
                    title={'Gerenciar minhas compras'}
                    isLoading={loadingPlan}
                    onPress={sendToCustomerPortalOfGuru}
                    borderRadius="5px"
                    colorbutton={
                      customColor &&
                        customColor.button &&
                        customColor.button.backgroundAuth
                        ? customColor.button.backgroundAuth
                        : THEME.COLORS.PRIMARY_800
                    }
                    colortitle={
                      customColor &&
                        customColor.button &&
                        customColor.button.textAuth
                        ? customColor.button.textAuth
                        : THEME.COLORS.TEXT_BUTTON
                    }
                  ></Button>
                </ViewButton>
              </ViewPlan>
            )}
            <Content>
              <StandardText
                style={{
                  fontFamily:
                    customFont && customFont.fontFamilySubtitle
                      ? customFont.fontFamilySubtitle
                      : THEME.FONTFAMILY.MEDIUM,
                }}
                color={
                  customColor && customColor.text && customColor.text.auth
                    ? customColor.text.auth
                    : THEME.COLORS.TEXT_ABOUT
                }
                padding="1rem 0rem"
                textAlign="left"
              >
                EDITAR INFORMAÇÕES:
              </StandardText>
              <ViewDescription>
                <SmallText
                  style={{
                    fontFamily:
                      customFont && customFont.fontFamilyText
                        ? customFont.fontFamilyText
                        : THEME.FONTFAMILY.REGULAR,
                  }}
                  color={
                    customColor && customColor.text && customColor.text.auth
                      ? customColor.text.auth
                      : THEME.COLORS.TEXT_ABOUT
                  }
                  textAlign="left"
                >
                  Email: {Email}
                </SmallText>
              </ViewDescription>
              <ViewDescription>
                <SmallText
                  style={{
                    fontFamily:
                      customFont && customFont.fontFamilyText
                        ? customFont.fontFamilyText
                        : THEME.FONTFAMILY.REGULAR,
                  }}
                  color={
                    customColor && customColor.text && customColor.text.auth
                      ? customColor.text.auth
                      : THEME.COLORS.TEXT_ABOUT
                  }
                  textAlign="left"
                >
                  Nome Completo:
                </SmallText>
              </ViewDescription>
              <ViewTextInput>
                <ViewText>
                  <TextInput
                    placeholder="Nome"
                    keyboardType="default"
                    returnKeyType="go"
                    value={Nome_Completo}
                    onChangeText={(value) => {
                      setUser({ ...user, Nome_Completo: value });
                      setErrorName(null);
                    }}
                    error={!!errorName}
                  />
                </ViewText>
                <ViewHelper>
                  <HelperText type="error" visible={errorName}>
                    {errorName}
                  </HelperText>
                </ViewHelper>
              </ViewTextInput>
              <ViewDescription>
                <SmallText
                  style={{
                    fontFamily:
                      customFont && customFont.fontFamilyText
                        ? customFont.fontFamilyText
                        : THEME.FONTFAMILY.REGULAR,
                  }}
                  color={
                    customColor && customColor.text && customColor.text.auth
                      ? customColor.text.auth
                      : THEME.COLORS.TEXT_ABOUT
                  }
                  textAlign="left"
                >
                  Celular:
                </SmallText>
              </ViewDescription>
              <ViewTextInput>
                <ViewText>
                  <TextInput
                    placeholder="(DDD)99999-9999"
                    keyboardType="default"
                    returnKeyType="go"
                    value={Celular}
                    onChangeText={(value) => {
                      setUser({ ...user, Celular: value });
                      setErrorCellphone(null);
                    }}
                    error={!!errorCellphone}
                  />
                </ViewText>
                <ViewHelper>
                  <HelperText type="error" visible={errorCellphone}>
                    {errorCellphone}
                  </HelperText>
                </ViewHelper>
              </ViewTextInput>
              <ViewDescription>
                <SmallText
                  style={{
                    fontFamily:
                      customFont && customFont.fontFamilyText
                        ? customFont.fontFamilyText
                        : THEME.FONTFAMILY.REGULAR,
                  }}
                  color={
                    customColor && customColor.text && customColor.text.auth
                      ? customColor.text.auth
                      : THEME.COLORS.TEXT_ABOUT
                  }
                  textAlign="left"
                >
                  {CPF ? 'CPF:' : 'Documento:'}
                </SmallText>
              </ViewDescription>
              <ViewTextInput>
                <ViewText>
                  <TextInput value={CPF ? CPF : Doc} />
                </ViewText>
                <ViewHelper></ViewHelper>
              </ViewTextInput>
              {guruUserToken && editUserAccess && (
                <DataTable
                  columns={columns}
                  data={data}
                  customStyles={customStyles}
                />
              )}
              <Modal
                visible={isCalendarVisible}
                animationType="fade"
                transparent={true}
              >
                <ViewModal>
                  <View style={styles.modalView}>
                    <Calendar
                      onDayPress={(day) => {
                        setNewExpirationDate(day.dateString);
                      }}
                      markedDates={{
                        [newExpirationDate]: {
                          selected: true,
                          disableTouchEvent: true,
                          selectedColor: '#2B93ED',
                        },
                      }}
                      renderArrow={renderArrow}
                    />
                    <ViewRow>
                      <Button
                        onPress={() => handleDateConfirm(newExpirationDate)}
                        title={'Salvar'}
                        fontFamily={
                          customFont && customFont.fontFamilyText
                            ? customFont.fontFamilyText
                            : THEME.FONTFAMILY.LIGHT
                        }
                        fontSize={
                          customFont && customFont.fontXS
                            ? customFont.fontXS
                            : THEME.FONTSIZE.EXTRASMALL
                        }
                      />
                      <Button
                        onPress={() => setCalendarVisible(false)}
                        title={'Cancelar'}
                        fontFamily={
                          customFont && customFont.fontFamilyText
                            ? customFont.fontFamilyText
                            : THEME.FONTFAMILY.LIGHT
                        }
                        fontSize={
                          customFont && customFont.fontXS
                            ? customFont.fontXS
                            : THEME.FONTSIZE.EXTRASMALL
                        }
                      />
                    </ViewRow>
                  </View>
                </ViewModal>
              </Modal>
              {visibleAlert && (
                <AlertBox
                  message={message}
                  visible={visibleAlert}
                  onClose={hideAlert}
                />
              )}
              <ViewButton>
                <Button
                  title={'SALVAR'}
                  isLoading={loadingSave}
                  onPress={handleSave}
                  borderRadius="5px"
                  colorbutton={
                    customColor && customColor.button && customColor.button.backgroundAuth
                      ? customColor.button.backgroundAuth
                      : THEME.COLORS.PRIMARY_800
                  }
                  colortitle={
                    customColor && customColor.button && customColor.button.textAuth
                      ? customColor.button.textAuth
                      : THEME.COLORS.TEXT_BUTTON
                  }
                ></Button>
              </ViewButton>
            </Content>
          </VerticalScroll>
        ) : (
          <Container justifyContent="center">
            <StandardText
              style={{
                fontFamily:
                  customFont && customFont.fontFamilySubtitle
                    ? customFont.fontFamilySubtitle
                    : THEME.FONTFAMILY.MEDIUM,
              }}
              color={
                customColor && customColor.text && customColor.text.auth
                  ? customColor.text.auth
                  : THEME.COLORS.TEXT_ABOUT
              }
            >
              Ops, acesso negado!
            </StandardText>
            <SmallText
              style={{
                fontFamily:
                  customFont && customFont.fontFamilyText
                    ? customFont.fontFamilyText
                    : THEME.FONTFAMILY.REGULAR,
              }}
              color={
                customColor && customColor.text && customColor.text.auth
                  ? customColor.text.auth
                  : THEME.COLORS.TEXT_ABOUT
              }
              margin="2rem"
            >
              Se você já possui cadastro, clique no botão abaixo para fazer
              login com o seu email e senha:
            </SmallText>
            <Button
              title={'Login'}
              onPress={() => navigation.navigate('Login')}
              colorbutton={
                customColor &&
                  customColor.button &&
                  customColor.button.backgroundAuth
                  ? customColor.button.backgroundAuth
                  : THEME.COLORS.PRIMARY_800
              }
              colortitle={
                customColor && customColor.button && customColor.button.textAuth
                  ? customColor.button.textAuth
                  : THEME.COLORS.TEXT_BUTTON
              }
            ></Button>
          </Container>
        )}
      </SafeAreaView>
    </Provider>
  );
}

const styles = StyleSheet.create({
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
});
