export const getPlatformForProduct = (product) => {
  if (/^[a-zA-Z0-9]{20}$/.test(product.productId)) return "Pagarme";
  if (/^\d+$/.test(product.productId)) return "Guru";
  return "-";
};

export const getProductName = (productId) => {
  if (typeof productId === 'string' && productId.startsWith("iap")) {
    if (/\.monthly\.(\d+)$/.test(productId)) {
      return `IAP - Plano Mensal ${productId.match(/\.monthly\.(\d+)$/)[1]}`;
    }
    if (/\.quarterly\.(\d+)$/.test(productId)) {
      return `IAP - Plano Trimestral ${productId.match(/\.quarterly\.(\d+)$/)[1]}`;
    }
    if (/\.semiannual\.(\d+)$/.test(productId)) {
      return `IAP - Plano Semestral ${productId.match(/\.semiannual\.(\d+)$/)[1]}`;
    }
    if (/\.yearly\.(\d+)$/.test(productId)) {
      return `IAP - Plano Anual ${productId.match(/\.yearly\.(\d+)$/)[1]}`;
    }
    return "IAP";
  }

  return productId || "Produto Inválido";
};