export const generateCSVContent = (headers, dataRows) => {
    const escapeCSV = (value) =>
        `"${(value || '').toString().replace(/"/g, '""')}"`;

    const csvHeader = headers.map(escapeCSV).join(',');
    const csvRows = dataRows.map(row =>
        Object.values(row).map(escapeCSV).join(',')
    );

    return `data:text/csv;charset=utf-8,${[csvHeader, ...csvRows].join('\n')}`;
};
