import React, { createContext, useContext, useEffect, useState } from 'react';
import { fetchAndActivate, getValue } from "firebase/remote-config";
import { remoteConfig } from '../services/firebase';

const RemoteConfigContext = createContext(undefined);

export const RemoteConfigProvider = ({ children }) => {
    const [config, setConfig] = useState({
        hasExternalLink: false,
        hasPlanner: false,
        hasScheduleNotifications: false,
        hasGeneralNotifications: false,
        hasComments: false,
        hasProgress: false,
        hasProfileInfos: false,
        hasDownload: false,
        api_key_panda: '',
        isAppUnavailable: false,
        hasCommunity: false,
        paymentGateway: '',
        hasForm: false,
        hasIAP: '',
    });

    useEffect(() => {
        // remoteConfig.settings.minimumFetchIntervalMillis = 3600000
        remoteConfig.settings = {
            minimumFetchIntervalMillis: 0,
        };
        remoteConfig.defaultConfig = {
            hasExternalLink: "false",
            hasPlanner: "false",
            hasScheduleNotifications: "false",
            hasGeneralNotifications: "false",
            hasComments: "false",
            hasProgress: "false",
            hasProfileInfos: "false",
            hasDownload: "false",
            api_key_panda: "",
            isAppUnavailable: "false",
            hasCommunity: "false",
            paymentGateway: "",
            hasForm: "false",
            hasIAP: "",
        }
        fetchAndActivate(remoteConfig)
            .then(() => {
                setConfig({
                    hasExternalLink: getValue(remoteConfig, "hasExternalLink").asBoolean(),
                    hasPlanner: getValue(remoteConfig, "hasPlanner").asBoolean(),
                    hasScheduleNotifications: getValue(remoteConfig, "hasScheduleNotifications").asBoolean(),
                    hasGeneralNotifications: getValue(remoteConfig, "hasGeneralNotifications").asBoolean(),
                    hasComments: getValue(remoteConfig, "hasComments").asBoolean(),
                    hasProgress: getValue(remoteConfig, "hasProgress").asBoolean(),
                    hasProfileInfos: getValue(remoteConfig, "hasProfileInfos").asBoolean(),
                    hasDownload: getValue(remoteConfig, "hasDownload").asBoolean(),
                    api_key_panda: getValue(remoteConfig, "api_key_panda").asString(),
                    isAppUnavailable: getValue(remoteConfig, "isAppUnavailable").asBoolean(),
                    hasCommunity: getValue(remoteConfig, "hasCommunity").asBoolean(),
                    paymentGateway: getValue(remoteConfig, "paymentGateway").asString(),
                    hasForm: getValue(remoteConfig, "hasForm").asBoolean(),
                    hasIAP: getValue(remoteConfig, "api_key_appl").asString() || getValue(remoteConfig, "api_key_goog").asString() ,
                });
            })
            .catch((err) => {
                console.error('Error fetching remote config:', err);
            });
    }, []);

    return (
        <RemoteConfigContext.Provider value={config}>
            {children}
        </RemoteConfigContext.Provider>
    );
};

export const useRemoteConfigContext = () => {
    const context = useContext(RemoteConfigContext);
    if (context === undefined) {
        throw new Error('useRemoteConfigContext must be used within a RemoteConfigProvider');
    }
    return context;
};