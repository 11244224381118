export const formatDateBR = (date) => {
    if (!date) return '-';

    // Se a data já estiver no formato "DD/MM/YYYY", retorna-a
    if (typeof date === 'string' && /^\d{2}\/\d{2}\/\d{4}$/.test(date)) return date;

    let dateObj = null;
    if (date instanceof Date) {
        dateObj = date;
    } else if (typeof date?.toDate === 'function') {
        dateObj = date.toDate();
    } else {
        dateObj = new Date(date);
    }

    return !isNaN(dateObj.getTime())
        ? dateObj.toLocaleDateString('pt-BR', { year: 'numeric', month: '2-digit', day: '2-digit' })
        : '-';
};