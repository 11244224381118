import React from 'react';
import { View, Text, StyleSheet } from 'react-native';

const CreditCardPreview = ({ cardNumber, holderName, expirationDate, cvv }) => {
  return (
    <View style={styles.cardContainer}>
      <Text style={styles.cardNumber}>
        {cardNumber ? cardNumber.replace(/(.{4})(?=.)/g, '$1 ') : '#### #### #### ####'}
      </Text>
      <Text style={styles.cardHolderName}>{holderName || 'NOME NO CARTAO'}</Text>
      <View style={styles.cardDetails}>
        <Text style={styles.expirationDate}>{expirationDate || 'MM/YY'}</Text>
        <Text style={styles.cvv}>{cvv || 'CVV'}</Text>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  cardContainer: {
    width: 300,
    height: 180,
    borderRadius: 10,
    backgroundColor: '#1E1E1E',
    padding: 20,
    marginVertical: 20,
    justifyContent: 'space-between',
  },
  cardNumber: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#FFF',
    letterSpacing: 2,
  },
  cardHolderName: {
    fontSize: 16,
    color: '#FFF',
    marginTop: 10,
  },
  cardDetails: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  expirationDate: {
    fontSize: 16,
    color: '#FFF',
  },
  cvv: {
    fontSize: 16,
    color: '#FFF',
  },
});

export default CreditCardPreview;