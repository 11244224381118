import React, { useState, useEffect } from 'react';
import { Modal, Switch } from 'react-native';
import { ActivityIndicator } from 'react-native-paper';
import { doc, getDoc } from "firebase/firestore";

import TextInput from "../../../components/TextInput";
import {
  StandardText,
  FooterText,
  SmallText,
  SubTitle
} from "../../../config/theme/globalStyles";
import Button from "../../../components/Button";
import { ViewTextInput, ViewButton, ViewRow, ModalBackground, ModalContainer, Header, CloseButton, Content } from "./style";
import { getProductById } from '../../../services/guru';
import { firestore } from '../../../services/firebase';
import GenericProduct from "./GenericProduct";

const isProductOrEticket = (type) => {
  return type === 'product' || type === 'eticket';
};

const EditProductModal = ({ isVisible, onClose, onEdit, selectedProduct }) => {
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);

  const [productInfo, setProductInfo] = useState('');
  const [offersInfos, setOffersInfos] = useState('');
  const [saveButtonVisible, setSaveButtonVisible] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [productType, setProductType] = useState("");
  const [archived, setArchived] = useState(true);
  const [checkoutUrl, setCheckoutUrl] = useState('');
  const [description, setDescription] = useState('');

  const [hasExpirationDate, setHasExpirationDate] = useState(false);
  const [expirationDays, setExpirationDays] = useState(0);
  const [showExpirationDaysWarning, setShowExpirationDaysWarning] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (selectedProduct?.marketplace === "Guru Digital") {
      fetchGuruProductData();
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (showSuccessMessage) {
      const timer = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessMessage]);


  const fetchGuruProductData = async () => {
    try {
      setLoadingSearch(true);
      setErrorMessage(""); // Limpa qualquer mensagem de erro anterior

      const infos = await getProductById(selectedProduct.productId);
      setProductInfo(infos.product);
      setOffersInfos(infos.offers);
      setSaveButtonVisible(true);

      // Definindo o type e ajustando a expiração
      const guruType = infos.product.type;
      setProductType(guruType);
      setHasExpirationDate(isProductOrEticket(guruType));

      // Carregando dados adicionais do Firestore
      const productRef = doc(firestore, "products", selectedProduct?.id);
      const productDocSnap = await getDoc(productRef);
      if (productDocSnap.exists()) {
        const productData = productDocSnap.data();
        setDescription(productData?.description);
        setArchived(productData?.archived);
        setCheckoutUrl(productData?.checkoutUrl);
        setHasExpirationDate(productData?.hasExpirationDate);
        setExpirationDays(productData?.days);
      }
    } catch (error) {
      console.error('Erro ao buscar informações do produto:', error);
      // Define a mensagem de erro a ser exibida no modal
      setErrorMessage("ID Informado não foi encontrado nos produtos existentes na Guru.");
    } finally {
      setLoadingSearch(false);
    }
  };

  const handleEdit = async () => {
    try {
      setLoadingSave(true);

      if (
        (isProductOrEticket(productInfo?.type) || isProductOrEticket(productType)) &&
        !archived &&
        (!expirationDays || Number.isNaN(expirationDays))
      ) {
        setShowExpirationDaysWarning(true);
        return;
      }

      await onEdit(
        productInfo,
        offersInfos,
        hasExpirationDate,
        expirationDays,
        checkoutUrl,
        archived,
        description,
        selectedProduct
      );

      setShowSuccessMessage(true);
      onClose();
    } catch (error) {
      console.error('Erro ao salvar produto:', error);
    } finally {
      setLoadingSave(false);
    }
  };

  return (
    <Modal animationType="fade" visible={isVisible} transparent={true}>
      {selectedProduct?.marketplace === "Guru Digital" ? (
        <ModalBackground>
          <ModalContainer>
            <Header>
              <SubTitle padding="0.2rem 0rem" color="#2e2e2e">
                Editar produto:
              </SubTitle>
              <CloseButton onPress={onClose}>
                <StandardText color="#2e2e2e">X</StandardText>
              </CloseButton>
            </Header>

            <Content>
              <ViewTextInput>
                <StandardText padding="0.2rem 0rem" color="#2e2e2e">
                  Código: {selectedProduct?.productId}
                </StandardText>
              </ViewTextInput>

              {loadingSearch && (
                <ActivityIndicator
                  style={{
                    flex: 1,
                    backgroundColor: "#FFFFFF",
                    justifyContent: "center",
                    paddingVertical: "1rem",
                  }}
                  color="#2e2e2e"
                  size="large"
                />
              )}

              {saveButtonVisible && (
                <>
                  <ViewTextInput>
                    <TextInput
                      placeholder="Descrição"
                      backgroundColor="#ffffff"
                      color="#2e2e2e"
                      value={description}
                      onChangeText={(desc) => setDescription(desc)}
                    />
                  </ViewTextInput>

                  <ViewTextInput>
                    <TextInput
                      placeholder="Checkout URL"
                      backgroundColor="#ffffff"
                      color="#2e2e2e"
                      value={checkoutUrl}
                      onChangeText={(url) => setCheckoutUrl(url)}
                    />
                  </ViewTextInput>

                  <ViewTextInput>
                    <ViewRow>
                      <StandardText
                        padding="0.5rem 1rem 0.5rem 0rem"
                        color="#2e2e2e"
                        textAlign="left"
                      >
                        Habilitar no aplicativo:
                      </StandardText>
                      <Switch
                        value={!archived}
                        onValueChange={(value) => setArchived(!value)}
                      />
                    </ViewRow>
                  </ViewTextInput>

                  {isProductOrEticket(productType) && (
                    <ViewTextInput>
                      <SmallText
                        padding="0.2rem 0rem"
                        color="#2e2e2e"
                        textAlign="left"
                      >
                        Data de Validade após Compra:
                      </SmallText>
                      <ViewTextInput>
                        <TextInput
                          placeholder="Dias de Expiração"
                          backgroundColor="#ffffff"
                          color="#2e2e2e"
                          value={expirationDays?.toString() || 0}
                          onChangeText={(days) =>
                            setExpirationDays(parseInt(days, 10))
                          }
                          keyboardType="numeric"
                        />
                      </ViewTextInput>

                      {showExpirationDaysWarning && (
                        <FooterText color="red">
                          Por favor, preencha os dias de expiração para salvar as alterações.
                        </FooterText>
                      )}
                    </ViewTextInput>
                  )}

                  {productType === "plan" && (
                    <ViewTextInput>
                      <ViewRow>
                        <StandardText
                          padding="0.5rem 1rem 0.5rem 0rem"
                          color="#2e2e2e"
                          textAlign="left"
                        >
                          Data de Validade após Compra:
                        </StandardText>
                        <Switch
                          value={hasExpirationDate}
                          onValueChange={(value) => setHasExpirationDate(value)}
                        />
                      </ViewRow>
                      <SmallText
                        padding="0.2rem 0rem"
                        color="#2e2e2e"
                        textAlign="left"
                      >
                        ⚠ ATENÇÃO: Apenas adicione uma data de validade se esse
                        produto for uma venda única (one-time purchase).
                      </SmallText>

                      {hasExpirationDate && (
                        <ViewTextInput>
                          <TextInput
                            placeholder="Dias de Expiração"
                            backgroundColor="#ffffff"
                            color="#2e2e2e"
                            value={expirationDays?.toString() || 0}
                            onChangeText={(days) =>
                              setExpirationDays(parseInt(days, 10))
                            }
                            keyboardType="numeric"
                          />
                        </ViewTextInput>
                      )}
                    </ViewTextInput>
                  )}

                  <ViewButton style={{ marginTop: '1rem' }}>
                    <Button
                      title="Salvar"
                      isLoading={loadingSave}
                      onPress={handleEdit}
                      colorbutton={'#3FC380a0'}
                      colortitle={'white'}
                    />
                    <Button
                      title="Cancelar"
                      onPress={onClose}
                      margin="0rem 0rem 0rem 1rem"
                      colorbutton={'#FA5D5Da0'}
                      colortitle={'white'}
                    />
                  </ViewButton>
                </>
              )}

              {errorMessage !== "" && (
                <FooterText color="red" padding="0.5rem 0rem">
                  {errorMessage}
                </FooterText>
              )}
            </Content>
          </ModalContainer>
        </ModalBackground>
      ) : (
        <ModalBackground>
          <GenericProduct productData={selectedProduct} onClose={onClose} />
        </ModalBackground>
      )}
    </Modal>
  );
};

export default EditProductModal;