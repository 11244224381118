import React, { useState, useEffect } from "react";
import { View, ScrollView, Modal } from "react-native";
import {
  ContentDesktop,
  ContentMobile,
  ContentList,
  Image,
  ViewVideoDesktop,
  ImageDesktop,
  ViewText,
  ViewButton,
  ViewVideo,
  ContentIcon,
  ViewSwitch,
  ViewModal,
  ImageAdmin,
  ViewVideoAdmin,
  ViewVideoDesktopAdmin,
  ContainerAdmin,
  ViewTextInput,
} from "./style";
import HeaderPurchase from "../../components/HeaderPurchase";
import Button from "../../components/Button";
import Footer from "../../components/Footer";
import ViewPortProvider from "../../hooks/ViewPortProvider";
import useViewport from "../../hooks/useViewport";
import THEME from "../../config/theme";
import { ActivityIndicator } from "react-native-paper";
import {
  FooterText,
  SmallText,
  Title,
  MainTitle,
  SubTitle,
  StandardText,
} from "../../config/theme/globalStyles";
import { auth } from "../../services/firebase";
import { onAuthStateChanged, signInAnonymously } from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { firestore } from "../../services/firebase/index";
import { useNavigation } from "@react-navigation/native";
import { SafeAreaView } from "react-native";
import VideoPlayer from "../../components/VideoPlayer";
import { createCheckoutSession } from "../../services/stripe/createCheckoutSession";
import { Provider, Switch, HelperText } from "react-native-paper";
import TextInput from "../../components/TextInput";
import { AlertBox } from "../../components/AlertBox";
import { CommonActions } from "@react-navigation/native";
import { AntDesign, Feather } from '@expo/vector-icons';
import { linkValidator } from "../../utils/validators";

export function ClickCourse({ route }) {

  const { courseId } = route.params;
  const [course, setCourse] = useState([]);
  const [userId, setUserId] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {
    title,
    image,
    infos,
    smallText,
    salesPageUrl,
    footerText,
    price,
    priceId,
    previewVideo,
    priceIdHidden,
    mode,
    backgroundImage,
    days,
    archived,
    redirectUrl
  } = course;

  const imageUrl = backgroundImage?.url || image;

  const navigation = useNavigation();

  const [youtube, setYoutube] = useState({ value: "", error: "" });
  const [loadingSave, setLoadingSave] = useState(false);
  const [visibleAlert, setVisibleAlert] = useState(false);
  const [message, setMessege] = useState(null);
  const [isReadyForSale, setIsReadyForSale] = useState(null);

  const [visible, setVisible] = useState(false);
  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);

  const toggleSwitch = () => setCourse(previousState => { return { ...previousState, archived: !previousState.archived } });
  const toggleSwitch2 = () => setCourse(previousState => { return { ...previousState, priceIdHidden: !previousState.priceIdHidden } });

  const showAlert = (message) => {
    setVisibleAlert(true);
    setMessege(message);
  };


  const getUserInformation = async (userId) => {
    const docRef = doc(firestore, "users", userId);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      setUser(docSnap.data());
    }
    setIsLoading(false)
  };

  const reloadPage = () => {
    hideAlert;
    location.reload()
  }

  const hideAlert = (status) => {
    setVisibleAlert(status);
    navigation.dispatch(
      CommonActions.reset({
        index: 0,
        routes: [
          {
            name: "Produto",
            params: {
              courseId: courseId,
            },
          },
        ],
      })
    )
  };

  const validation = () => {
    const youtubeError = linkValidator(youtube.value);

    if (youtubeError) {
      setYoutube({ ...youtube, error: youtubeError });
      setLoadingSave(false);
      return;
    }
    return false;
  };

  const OutsideView = () => {
    const { width } = useViewport();
    const breakpoint = 1080;

    return width < breakpoint ? (
      <View>
        <ViewImage></ViewImage>
        <ViewFlatlist></ViewFlatlist>
      </View>
    ) : (
      <View style={{ flexDirection: "row", justifyContent: "center" }}>
        <ViewImage></ViewImage>
        <ViewFlatlist></ViewFlatlist>
      </View>
    );
  };

  const OutsideViewAdmin = () => {
    const { width } = useViewport();
    const breakpoint = 1080;

    return width < breakpoint ? (
      <View>
        <ViewImageAdmin></ViewImageAdmin>
        <ViewFlatlistAdmin></ViewFlatlistAdmin>
      </View>
    ) : (
      <View style={{ flexDirection: "row", justifyContent: "center" }}>
        <ViewImageAdmin></ViewImageAdmin>
        <ViewFlatlistAdmin></ViewFlatlistAdmin>
      </View>
    );
  };

  const getCourse = async () => {
    const docRef = doc(firestore, "courses", courseId);
    const docSnap = await getDoc(docRef);

    if (!docSnap.exists()) return;
    const course = { id: docSnap.id, ...docSnap.data() };
    setCourse(() => course);
    if (docSnap.exists() && docSnap.data().previewVideo === null) {
      setYoutube({ value: "", error: "" })
    } else {
      setYoutube({ value: docSnap.data().previewVideo, error: "" })
    }
  };

  const onPurchaseButtonClick = () => {
    setLoading(true);
    userId
      ? createCheckoutSession(userId, priceId, mode)
      : navigation.navigate("Cadastro", {
        purchaseType: "COURSE",
        priceId,
        mode,
      });
  };

  const editCourse = async () => {
    setLoadingSave(true);

    if (priceIdHidden === false && archived === false) {
      const docRef = doc(firestore, "functions", "data-module-exports");
      const docSnap = await getDoc(docRef);
      if (docSnap.exists() && docSnap.data().moduleExports[priceId]?.days) {
        setIsReadyForSale(true);
        validation();
        if (validation() === false) {
          const editCourseYoutube = async () => {
            const courseRef = doc(firestore, "courses", courseId);
            if (youtube.value) {
              await setDoc(
                courseRef,
                {
                  previewVideo: youtube.value,
                  infos: infos,
                  archived: archived,
                  priceIdHidden: priceIdHidden,
                  smallText: smallText,
                  salesPageUrl: salesPageUrl || null,
                  hasOutsidePage: salesPageUrl ? true : false
                },
                { merge: true }
              )
                .then(() => {
                  showAlert("Alteração feita com sucesso! :)");
                })
                .catch((e) => {
                  console.log("EditCourse, salvar: " + e);
                })
                .finally(() => {
                  setLoadingSave(false);
                  setVisible(false);
                });
            } else {
              await setDoc(
                courseRef,
                {
                  previewVideo: null,
                  infos: infos,
                  archived: archived,
                  priceIdHidden: priceIdHidden,
                  title: title,
                  days: days,
                  smallText: smallText,
                  salesPageUrl: salesPageUrl || null,
                  hasOutsidePage: salesPageUrl ? true : false
                },
                { merge: true }
              )
                .then(() => {
                  showAlert("Alteração feita com sucesso! :)");
                })
                .catch((e) => {
                  console.log("EditCourse, salvar: " + e);
                })
                .finally(() => {
                  setLoadingSave(false);
                  setVisible(false);
                });
            }
          };
          editCourseYoutube();
        }
      } else {
        setLoadingSave(false);
        setIsReadyForSale(false);
      }
    } else {
      validation();
      if (validation() === false) {
        const editCourseYoutube = async () => {
          const courseRef = doc(firestore, "courses", courseId);
          if (youtube.value) {
            await setDoc(
              courseRef,
              {
                previewVideo: youtube.value,
                infos: infos,
                archived: archived,
                priceIdHidden: priceIdHidden,
                smallText: smallText,
                salesPageUrl: salesPageUrl || null,
                hasOutsidePage: salesPageUrl ? true : false
              },
              { merge: true }
            )
              .then(() => {
                showAlert("Alteração feita com sucesso! :)");
              })
              .catch((e) => {
                console.log("EditCourse, salvar: " + e);
              })
              .finally(() => {
                setLoadingSave(false);
                setVisible(false);
              });
          } else {
            await setDoc(
              courseRef,
              {
                previewVideo: null,
                infos: infos,
                archived: archived,
                priceIdHidden: priceIdHidden,
                title: title,
                days: days,
                smallText: smallText,
                salesPageUrl: salesPageUrl || null,
                hasOutsidePage: salesPageUrl ? true : false
              },
              { merge: true }
            )
              .then(() => {
                showAlert("Alteração feita com sucesso! :)");
              })
              .catch((e) => {
                console.log("EditCourse, salvar: " + e);
              })
              .finally(() => {
                setLoadingSave(false);
                setVisible(false);
              });
          }
        };
        editCourseYoutube();
      }
    };
  }

  const ViewImageAdmin = () => {
    const { width } = useViewport();
    const breakpoint = 1080;

    return width < breakpoint ? (
      <ContentMobile padding="2rem 1rem">
        {previewVideo ? (
          <ViewVideoAdmin>
            <VideoPlayer previewVideo={previewVideo} />
          </ViewVideoAdmin>
        ) : (
          <ImageAdmin source={{ uri: imageUrl }} />
        )}
        <Title textAlign="left" margin="0.5rem 0rem">
          {title}
        </Title>
        {infos && Array.isArray(infos) && infos.map((i, index) => (
          <SmallText key={index} margin="1rem 0rem 0.5rem 0rem" textAlign="justify">
            {i}
          </SmallText>
        ))}
      </ContentMobile>
    ) : (
      <ContentDesktop>
        {previewVideo ? (
          <ViewVideoDesktopAdmin>
            <VideoPlayer previewVideo={previewVideo} />
          </ViewVideoDesktopAdmin>
        ) : (
          <ImageDesktop source={{ uri: imageUrl }} />
        )}
        <Title textAlign="left" margin="0.5rem 0rem">
          {title}
        </Title>
        {infos && Array.isArray(infos) && infos.map((i, index) => (
          <SmallText key={index} margin="1rem 0rem 0.5rem 0rem" textAlign="justify">
            {i}
          </SmallText>
        ))}
      </ContentDesktop>
    );
  };

  const ViewImage = () => {
    const { width } = useViewport();
    const breakpoint = 1080;

    return width < breakpoint ? (
      <ContentMobile padding="1rem">
        {previewVideo ? (
          <ViewVideo>
            <VideoPlayer previewVideo={previewVideo} />
          </ViewVideo>
        ) : (
          <Image source={{ uri: imageUrl }} />
        )}
        <Title textAlign="left" margin="0.5rem 0rem">
          {title}
        </Title>
        {infos && Array.isArray(infos) && infos.map((i, index) => (
          <SmallText key={index} margin="1rem 0rem 0.5rem 0rem" textAlign="justify">
            {i}
          </SmallText>
        ))}
      </ContentMobile>
    ) : (
      <ContentDesktop>
        {previewVideo ? (
          <ViewVideoDesktop>
            <VideoPlayer previewVideo={previewVideo} />
          </ViewVideoDesktop>
        ) : (
          <ImageDesktop source={{ uri: imageUrl }} />
        )}
        <Title textAlign="left" margin="0.5rem 0rem">
          {title}
        </Title>
        {infos && Array.isArray(infos) && infos.map((i, index) => (
          <SmallText key={index} margin="1rem 0rem 0.5rem 0rem" textAlign="justify">
            {i}
          </SmallText>
        ))}
      </ContentDesktop>
    );
  };

  const ViewFlatlistAdmin = () => {
    const { width } = useViewport();
    const breakpoint = 1080;

    return width < breakpoint ? (
      <View>
        <ViewText>
          <Title margin="0.5rem 0rem">{price}</Title>
          <FooterText>{footerText}</FooterText>
          <SmallText>{smallText}</SmallText>
        </ViewText>
        <ViewButton padding="0.5rem 0rem" flexDirection="row">
          {priceIdHidden == true ? (
            <></>
          ) : (
            <Button
              title={"Comprar agora"}
              isLoading={loading}
              onPress={user?.isAdmin ? () => undefined : onPurchaseButtonClick}
            ></Button>
          )}
        </ViewButton>
      </View>
    ) : (
      <ContentList>
        <View>
          <MainTitle textAlign="left" margin="0.5rem 0rem">
            {price}
          </MainTitle>
          <FooterText textAlign="justify">{footerText}</FooterText>
          <SmallText textAlign="justify">{smallText}</SmallText>
        </View>
        <ViewButton alignSelf="flex-start" padding="0.5rem 0rem" flexDirection="row">
          {priceIdHidden == true ? (
            <></>
          ) : (
            <Button
              title={"Comprar agora"}
              isLoading={loading}
              onPress={user?.isAdmin ? () => undefined : onPurchaseButtonClick}
            ></Button>
          )}
        </ViewButton>
      </ContentList>
    );
  };

  const ViewFlatlist = () => {
    const { width } = useViewport();
    const breakpoint = 1080;

    return width < breakpoint ? (
      <View>
        <ViewText>
          <Title margin="0.5rem 0rem">{price}</Title>
          <FooterText>{footerText}</FooterText>
          <SmallText>{smallText}</SmallText>
        </ViewText>
        <ViewButton padding="1rem 0rem">
          {priceIdHidden == true ? (
            <></>
          ) : (
            <Button
              title={"Comprar agora"}
              isLoading={loading}
              onPress={user?.isAdmin ? () => undefined : onPurchaseButtonClick}
            ></Button>
          )}
        </ViewButton>
      </View>
    ) : (
      <ContentList>
        <View>
          <MainTitle textAlign="left" margin="0.5rem 0rem">
            {price}
          </MainTitle>
          <FooterText textAlign="justify">{footerText}</FooterText>
          <SmallText textAlign="justify">{smallText}</SmallText>
        </View>
        <ViewButton alignSelf="flex-start" padding="1rem 0rem">
          {priceIdHidden == true ? (
            <></>
          ) : (
            <Button
              title={"Comprar agora"}
              isLoading={loading}
              onPress={user?.isAdmin ? () => undefined : onPurchaseButtonClick}
            ></Button>
          )}
        </ViewButton>
      </ContentList>
    );
  };

  useEffect(() => {
    getCourse();
    onAuthStateChanged(auth, (user) => {
      if (user && !user.isAnonymous) {
        setUserId(user.uid);
        getUserInformation(user.uid)
      } else if(!user) {  
        // Nenhum usuário está logado, realizar login anônimo.
       signInAnonymously(auth)
       .then(() => {
        setIsLoading(false);
       // Login anônimo realizado com sucesso.
       })
       .catch((error) => {
       // Houve um erro ao realizar login anônimo.
       console.error(error);
       });
      }
      else {
        setIsLoading(false)
      }
    });
  }, []);


  return isLoading ? (
    <ActivityIndicator
      style={{
        flex: 1,
        backgroundColor: THEME.COLORS.BACKGROUND_ABOUT,
        justifyContent: "center",
      }}
      color={THEME.COLORS.PRIMARY_800}
    />
  ) : user?.isAdmin ? (
    <Provider>
      <ViewPortProvider>
        <ContainerAdmin>
          <ContentIcon onPress={() => showModal()}>
            <Feather
              name="edit-3"
              size={18}
              color={THEME.COLORS.TEXT_ABOUT}
            />
            <FooterText color={THEME.COLORS.TEXT_ABOUT} padding="0.5rem">
              Editar Página
            </FooterText>
          </ContentIcon>
          <View style={{ justifyContent: "space-between", flex: 1 }}>
            <OutsideViewAdmin></OutsideViewAdmin>
            <Modal
              animationType="fade"
              visible={visible}
              onDismiss={hideModal}
            >
              <ViewModal>
                <ScrollView>
                  <SubTitle
                    padding="0rem 0rem 0.5rem 0rem"
                    color="#2e2e2e"
                  >
                    Editar produto:
                  </SubTitle>
                  <SmallText textAlign="flex-start">
                    • O preço e o nome do produto não podem ser alterados por aqui, mas caso seja necessário, entre em contato com a gente!
                  </SmallText>
                  <SmallText textAlign="flex-start">
                    • Caso você ainda esteja editando a página de vendas do seu produto, mantenha-o arquivado. Dessa forma, ele não irá aparecer na página de vendas do seu app.
                  </SmallText>
                  <SmallText textAlign="flex-start" margin="0rem 0rem 1rem 0rem">
                    • Quando a página do produto estiver pronta, você poderá publicá-lo e, automaticamente, ele estará habilitado para a venda! Caso você queira apenas divulgar seu produto e habilitar a venda apenas em datas específicas, desabilite o botão "desabilitar venda".
                  </SmallText>
                  <ViewTextInput>
                    <TextInput
                      label="Nome do produto"
                      keyboardType="default"
                      returnKeyType="go"
                      value={title}
                    />
                  </ViewTextInput>
                  {redirectUrl &&
                    <ViewTextInput>
                      <TextInput
                        label="Link para compra do produto"
                        keyboardType="default"
                        returnKeyType="go"
                        value={redirectUrl}
                      />
                    </ViewTextInput>
                  }
                  <ViewTextInput>
                    <TextInput
                      label="Página de vendas do produto (opcional)"
                      placeholder="Ex.: www.yourdomain.com/produto1"
                      keyboardType="default"
                      returnKeyType="go"
                      value={salesPageUrl}
                      onChange={({ target: { value } }) => {
                        setCourse(prevState => {
                          return { ...prevState, salesPageUrl: value, hasOutsidePage: value.length > 0 ? true : false }
                        });
                      }}
                    />
                  </ViewTextInput>
                  <ViewTextInput>
                    <TextInput
                      label="Validade de acesso (em dias)"
                      keyboardType="default"
                      returnKeyType="go"
                      value={days}
                    />
                  </ViewTextInput>
                  {Array.isArray(infos) && infos.map((item, index) => (
                    <ViewTextInput>
                      <TextInput
                        label="Descrição do produto:"
                        placeholder="Digitar descrição"
                        keyboardType="default"
                        returnKeyType="go"
                        value={item}
                        onChange={({ target: { value } }) => {
                          infos[index] = value
                          setCourse(prevState => {
                            return { ...prevState, infos: infos }
                          });
                        }}
                      />
                    </ViewTextInput>
                  ))}
                  <View style={{ flexDirection: "row", justifyContent: "space-evenly" }}>
                    <ContentIcon onPress={() => {
                      infos.push(`digitar parágrafo ${infos.length + 1}`)
                      setCourse(prevState => {
                        return { ...prevState, infos: infos }
                      });
                    }}>
                      <AntDesign
                        name="plus"
                        size={18}
                        color="#2e2e2e"
                      />
                      <FooterText color="#2e2e2e" padding="0.5rem">
                        Adicionar novo parágrafo
                      </FooterText>
                    </ContentIcon>
                    <ContentIcon onPress={() => {
                      infos.pop()
                      setCourse(prevState => {
                        return { ...prevState, infos: infos }
                      });
                    }}>
                      <AntDesign
                        name="minus"
                        size={18}
                        color="#2e2e2e"
                      />
                      <FooterText color="#2e2e2e" padding="0.5rem">
                        Remover último parágrafo
                      </FooterText>
                    </ContentIcon>
                  </View>
                  <ViewTextInput>
                    <TextInput
                      label="(Opcional) Vídeo de vendas:"
                      placeholder="Colar link do youtube"
                      keyboardType="default"
                      returnKeyType="go"
                      value={youtube.value}
                      onChangeText={(text) => {
                        setYoutube({ value: text, error: "" });
                      }}
                      error={!!youtube.error}
                    />
                    <HelperText type="error" visible={youtube.error}>
                      {youtube.error}
                    </HelperText>
                  </ViewTextInput>
                  <TextInput
                    label="Texto em destaque:"
                    placeholder="Ex.: ✔ Acesso válido por 1 ano"
                    keyboardType="default"
                    returnKeyType="go"
                    value={smallText}
                    onChange={({ target: { value } }) => {
                      setCourse(prevState => {
                        return { ...prevState, smallText: value }
                      });
                    }}
                  />
                  <ViewSwitch>
                    <StandardText color="#2e2e2e" padding="0.5rem">
                      Publicar:
                    </StandardText>
                    <Switch
                      trackColor={{ false: THEME.COLORS.PRIMARY_700, true: THEME.COLORS.PRIMARY_900 }}
                      thumbColor={!archived ? THEME.COLORS.PRIMARY_800 : "#f4f3f4"}
                      ios_backgroundColor="#3e3e3e"
                      activeThumbColor={THEME.COLORS.PRIMARY_800}
                      onValueChange={toggleSwitch}
                      value={!archived}
                    />
                  </ViewSwitch>
                  {archived == false ? (
                    <>
                      <ViewSwitch>
                        <StandardText color="#2e2e2e" padding="0.5rem">
                          Habilitar venda:
                        </StandardText>
                        <Switch
                          trackColor={{ false: "#767577", true: THEME.COLORS.PRIMARY_900 }}
                          thumbColor={!archived ? THEME.COLORS.PRIMARY_800 : "#f4f3f4"}
                          ios_backgroundColor="#3e3e3e"
                          activeThumbColor={THEME.COLORS.PRIMARY_800}
                          onValueChange={toggleSwitch2}
                          value={!priceIdHidden}
                        />
                      </ViewSwitch>
                    </>
                  ) : (
                    <></>
                  )}
                  {isReadyForSale == false && (
                    <StandardText color="#2e2e2e" padding="0.5rem">
                      Esse produto não está disponível para venda ainda! Por favor, aguarde uns minutos e tente novamente.
                    </StandardText>
                  )}
                  <ViewButton padding="0.5rem 0rem" flexDirection="row">
                    <Button
                      title={"Salvar"}
                      isLoading={loadingSave}
                      onPress={() => {
                        editCourse()
                      }}
                      margin="0rem 0.5rem"
                    ></Button>
                    <Button
                      title={"Cancelar"}
                      onPress={() => setVisible(false)}
                      margin="0rem 0.5rem"
                    ></Button>
                  </ViewButton>
                </ScrollView>
              </ViewModal>
            </Modal>
            {visibleAlert && (
              <AlertBox
                message={message}
                visible={visibleAlert}
                leftButtonFunction={reloadPage}
                onClose={hideAlert}
                leftButton={"OK"}
              ></AlertBox>
            )}
          </View>
        </ContainerAdmin>
      </ViewPortProvider>
    </Provider>
  ) : (
    <ViewPortProvider>
      <SafeAreaView
        style={{ backgroundColor: THEME.COLORS.BACKGROUND_ABOUT, flexGrow: 1 }}
      >
        <View style={{ justifyContent: "space-between", flex: 1 }}>
          <HeaderPurchase background={THEME.COLORS.BACKGROUND_HEADER} />
          <OutsideView></OutsideView>
          <Footer />
        </View>
      </SafeAreaView>
    </ViewPortProvider>
  )
}
