import React, { useState, useEffect } from 'react';
import { Modal, ScrollView } from 'react-native';
import TextInput from "../../../components/TextInput";
import { FooterText, SubTitle, StandardText } from "../../../config/theme/globalStyles";
import Button from "../../../components/Button";
import { ViewTextInput, ViewButton, ModalBackground, ModalContainer, Header, CloseButton, Content } from "./style";
import { getProductById } from '../../../services/guru';
import THEME from "../../../config/theme";
import CreateProductGenericForm from "./CreateProductGenericForm";
import { createNewProduct } from "./service/product-service";
import { useRemoteConfigContext } from '../../../contexts/useRemoteConfigContext';

const CreateProductModal = ({ isVisible, onClose, onCreate }) => {
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [productId, setProductId] = useState('');
  const [productInfo, setProductInfo] = useState('');
  const [offersInfos, setOffersInfos] = useState('');
  const [saveButtonVisible, setSaveButtonVisible] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const config = useRemoteConfigContext();

  const [alertState, setAlertState] = useState({
    visible: false,
    message: null,
  });

  const showAlert = (message) => {
    setAlertState({ visible: true, message });
  };

  useEffect(() => {
    if (showSuccessMessage) {
      const timer = setTimeout(() => {
        setShowSuccessMessage(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showSuccessMessage]);

  const handleCreate = async () => {
    try {
      setLoadingSave(true);
      const result = await onCreate(productInfo, offersInfos, productId);
      if (result.success) {
        showAlert("Novo produto adicionado com sucesso!");
        setShowSuccessMessage(true);
        onClose();
      } else if (result.error) {
        showAlert(result.error);
      }
    } catch (error) {
      console.error('Erro ao criar produto:', error);
      showAlert('Erro ao criar produto');
    } finally {
      setLoadingSave(false);
    }
  };

  const findPlanAndOffers = async () => {
    if (!productId.trim()) {
      showAlert("Por favor, digite o ID do produto");
      return;
    }

    try {
      setLoadingSearch(true);
      const infos = await getProductById(productId);

      if (!infos || !infos.product) {
        throw new Error("Product not found");
      }

      setAlertState({ visible: false, message: null });

      setProductInfo(infos.product);
      setOffersInfos(infos.offers);
      setSaveButtonVisible(true);
    } catch (error) {
      showAlert("Id do produto não encontrado na Guru.");
    } finally {
      setLoadingSearch(false);
    }
  };

  return (
    <Modal
      animationType="fade"
      isVisible={isVisible}
      onBackdropPress={onClose}
      transparent={true}
    >
      <ModalBackground>
        <ModalContainer>
          <ScrollView
            showsVerticalScrollIndicator={true}
            style={{ width: '100%', height: '100%' }}
          >
            <Header>
              {!saveButtonVisible && (
                <SubTitle padding="0.2rem 0rem" color="#2e2e2e">
                  Adicionar Produto no aplicativo:
                </SubTitle>
              )}
              <CloseButton onPress={onClose}>
                <StandardText color="#2e2e2e">X</StandardText>
              </CloseButton>
            </Header>

            {config.paymentGateway === "PAGARME" ? (
              <CreateProductGenericForm onClose={onClose} saveHandler={createNewProduct}></CreateProductGenericForm>
            ) : (
              <Content>
                <ViewTextInput>
                  <TextInput
                    placeholder="Digite o ID do Produto"
                    color="black"
                    backgroundColor="white"
                    value={productId}
                    onChangeText={(id) => {
                      setProductId(id);
                      if (id.trim()) {
                        setAlertState({ visible: false, message: null });
                      }
                    }}
                  />
                  <FooterText padding="0.2rem 0rem" color="black">
                    {!saveButtonVisible
                      ? "O ID do produto deve ser o código gerado na Guru Digital"
                      : "Produto encontrado com sucesso!"}
                  </FooterText>
                  {alertState.visible && (
                    <FooterText color="red">{alertState.message}</FooterText>
                  )}
                </ViewTextInput>
                <ViewButton>
                  {!saveButtonVisible &&
                    <Button
                      title="Buscar informações do produto"
                      colorbutton={'#3FC380a0'}
                      colortitle={'white'}
                      isLoading={loadingSearch}
                      onPress={findPlanAndOffers}
                      fontSize={THEME.FONTSIZE.EXTRASMALL}
                    />
                  }
                </ViewButton>
              </Content>
            )}
            {saveButtonVisible && (
              <ViewButton style={{ marginTop: '1rem' }}>
                <Button
                  title="Salvar"
                  isLoading={loadingSave}
                  onPress={handleCreate}
                  colorbutton={'#3FC380a0'}
                  colortitle={'white'}
                />
                <Button
                  title="Cancelar"
                  onPress={onClose}
                  margin="0rem 0rem 0rem 1rem"
                  colorbutton={'#FA5D5Da0'}
                  colortitle={'white'}
                />
              </ViewButton>
            )}
          </ScrollView>
        </ModalContainer>
      </ModalBackground>
    </Modal>
  );
};


export default CreateProductModal;